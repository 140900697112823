import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../CSS/general.css";
import { Loader, Icon, Dimmer, Image } from "semantic-ui-react";
import { authProvider } from "../AuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes, LEGACY_SITE } from "../Helpers/Constants";
import {
    Button,
    Table,
    Spinner,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalHeader,
    Input,
} from "reactstrap";
import { loginAuthProviderSignUp } from "../LoginAuthProviderSignUp";
import { Modal as BootstrapModal, Button as BSButton } from 'react-bootstrap';
import { Warning } from "./Warning";
import still from "../images/setupimages/signup.png";
import microsoft from "../images/setupimages/microsoft.png";
import veeam from "../images/setupimages/veeam.png";
import zadara from "../images/setupimages/zadara.png";
import checkTenant from "../images/Checktenant.png";
import microsoftwait from "../images/microsoftwait.png";
import stpperLoad from "../images/stpperLoad.gif";
import LoggedIn from "../images/LoggedIn.png";
import stepCompleted from "../images/stepCompleted.png";
import setupCompleted from "../images/setupCompleted.png";
import CloudCoverPermission from "../images/CloudCoverPermission.png";
import { ReactTooltip } from "./ReactTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimesCircle,
    faCheckCircle,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
//import { BiDashCircleFill } from 'react-bootstrap-icons';
import { isGetAccessor } from "typescript";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast,ToastContainer } from "react-toastify";
export class RegistrationProgressModernGuardian extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);

        let tenantConfirmed = false;
        if (props.hasConsented) {
            tenantConfirmed = true;
        }
        this.state = {
            initialFetch: true,
            inProgress: false,
            failed: false,
            deviceCode: "",
            showMicrosoftWarningPopUp: false,
            hasDeviceCode: false,
            hasSelectedFeatures: false,
            azureBackUpCheck: false,
            tempAzureBackpCheck: false,
            checkingTenant: false,
            currentStep: 0,
            showWarningText: false,
            hasSecurity: false,
            userSecurity: false,
            usingModernAuth: false,
            hasCAP: false,
            warningText: "",
            permissionLink: "",
            authType: "MFA",
            orgExists: false,
            finished: false,
            tenantId: props.tenantId,
            hasConsented: props.hasConsented,
            originalPassword: "",
            vboPassword: "",
            copied: false,
            email: "",
            name: "",
            tenantConfirmed: tenantConfirmed,
            isAdmin: false,
            adminCheck: false,
            resellerId: urlParams.get("resellerId") ? urlParams.get("resellerId") : "0",
            specificProd: urlParams.get("specificProd") ? urlParams.get("specificProd") : "false",
            teamsChatEnabled: false,
            tempTeamsChatEnabled: false,
            microsoft365Enable: false,
            tempMicrosoft365Enable: false,
            exchangeOnlineEnabled: urlParams.get("specificProd") ? urlParams.get("specificProd") === "true" && true : false,
            sharepointOnedriveEnabled: false,
            microsoftTeamsEnabled: false,
            orgTeamsChatEnabled:false,
            notificationEmail: "",
            newApp: props.newApp,
            modernCreateAdminGroup: false,
            hasCreateAdminGroup: false,
            groups: [],
            VeeamAdmin: true,
            Setting: false,
            MainPage: false,
            RestorePage: false,
            EndUserRestore: false,
            showModal: false,
        };
        this.getIsAdmin = this.getIsAdmin.bind(this);
        this.getLeftCard = this.getLeftCard.bind(this);
        this.getLeftCardButtons = this.getLeftCardButtons.bind(this);
        this.getRightCard = this.getRightCard.bind(this);
        this.getOrgExists = this.getOrgExists.bind(this);
        this.hasConsented = this.hasConsented.bind(this);
        this.confirmTenant = this.confirmTenant.bind(this);
        this.logout = this.logout.bind(this);
        this.getDeviceCode = this.getDeviceCode.bind(this);
        this.assignFeature = this.assignFeature.bind(this);
        this.createTennantOnVeeamDeviceCode =
            this.createTennantOnVeeamDeviceCode.bind(this);
        this.getProgressModern = this.getProgressModern.bind(this);
        this.modernAddToAdminGroup = this.modernAddToAdminGroup.bind(this);
        this.modernCreateBucket = this.modernCreateBucket.bind(this);
        this.AddAzureBackupAccount = this.AddAzureBackupAccount.bind(this);
        this.modernCreateAdminGroup = this.modernCreateAdminGroup.bind(this);
        this.getIsAdmin = this.getIsAdmin.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleTeamsChatChange = this.handleTeamsChatChange.bind(this);
        this.handleMicrosoft365Change = this.handleMicrosoft365Change.bind(this);
        this.handleExchangeOnlineChange = this.handleExchangeOnlineChange.bind(this);
        this.handleSharepointOnedriveChange = this.handleSharepointOnedriveChange.bind(this);
        this.handleMicrosoftTeamsChange = this.handleMicrosoftTeamsChange.bind(this);
        this.handleAzureBackupChange = this.handleAzureBackupChange.bind(this);
        this.handleWarningMessage = this.handleWarningMessage.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.modernCreateAdminGroup = this.modernCreateAdminGroup.bind(this);
        this.modernCreateAndAddAdminGroup = this.modernCreateAndAddAdminGroup.bind(this);
    }
    static displayName = RegistrationProgressModernGuardian.names;

    componentDidMount() {
        var accountInfo = authProvider.getAccountInfo();
        var email = accountInfo.account.userName;
        var name = accountInfo.account.name;
        this.setState(
            {
                email: email,
                notificationEmail: email,
                name: name,
            },
            () => {
                this.getIsAdmin();
            }
        );
    }

    handleInput(event) {
        let value = event.target.value;
        let name = event.target.name;
        this.setState({
            [name]: value,
        });
    }
    handleShowModal = () => {
        if (!this.state.tempMicrosoft365Enable && !this.state.tempAzureBackpCheck) {
            toast.error("Select either Protect Microsoft 365 Data or Protect Azure Configuration ", { autoClose: 3000 });
        }
        else { this.setState({ showModal: true }); }       
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleWarningMessage(showWarning, message, inProgress) {
        if (message && message.toLowerCase().includes("proxy.svc"))
            message = "Something went wrong, Please contact support.";
        this.setState({
            inProgress: inProgress,
            showWarningText: showWarning,
            warningText: message,
        });
    }

    handleTeamsChatChange(event) {
        this.setState({
            tempTeamsChatEnabled: !this.state.tempTeamsChatEnabled,
        });
    }
    handleMicrosoft365Change(event) {
        this.setState({
            tempMicrosoft365Enable: !this.state.tempMicrosoft365Enable,
        });
    }
    handleExchangeOnlineChange(event) {
        this.setState({
            exchangeOnlineEnabled: !this.state.exchangeOnlineEnabled,
        });
    } handleSharepointOnedriveChange(event) {
        this.setState({
            sharepointOnedriveEnabled: !this.state.sharepointOnedriveEnabled,
        });
    } handleMicrosoftTeamsChange(event) {
        if (this.state.microsoftTeamsEnabled) {
            this.setState({
                teamsChatEnabled: false,
            });
        }
        this.setState({
            microsoftTeamsEnabled: !this.state.microsoftTeamsEnabled,
        });
    }
    handleAzureBackupChange(event) {
        this.setState({
            tempAzureBackpCheck: !this.state.tempAzureBackpCheck,
        });
    }

    logout(event) {
        event.preventDefault();
        authProvider.logout();
        let signUpauthProvider = loginAuthProviderSignUp(window.location.href);
        signUpauthProvider.logout();
    }

    async getIsAdmin() {
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        this.setState(
            {
                inProgress: true,
            },
            () => {
                const config = {
                    headers: { Authorisation: `Bearer ` + tokenString },
                };
                Axios.get(API_ROUTE + Routes.GET_IS_ADMIN, config)
                    .then((response) => {
                        let isAdmin = response.data;
                        if (isAdmin) {
                            this.setState({
                                isAdmin: isAdmin,
                                inProgress: false,
                                adminCheck: true,
                            });
                        } else {
                            this.setState({
                                isAdmin: isAdmin,
                                adminCheck: true,
                            });
                        }
                    })
                    .catch((reason) => {
                        this.handleWarningMessage(true, reason.response.data, false);
                    });
            }
        );
    }

    getLeftCard() {
        let card = null;
        if (!this.state.adminCheck || this.state.checkingTenant) {
            card = (
                //<React.Fragment>
                //    <br />
                //    <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Checking your tenant and set up progress.</p>
                //</React.Fragment>
                <div style={{ padding: '14%', height: '100%' }}>
                    <img src={checkTenant} alt="Checking your tenant" className="mt-4" style={{ height: '3.5rem' }} />
                    <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Checking your tenant and setup progress.</p>
                    <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif", fontSize:'14px' }}>You may need to enable pop ups on your browser.</p>
                </div>
            );
        } else if (this.state.showWarningText) {
            if (this.state.warningText.includes("Please re-enter your device code")) {
                card = (
                    <React.Fragment>
                        <br />
                        <p>{this.state.warningText}</p>

                        <div className="btn-group mr-2 mb-1" role="group">
                            <Button
                                onClick={this.getDeviceCode}
                                color="primary"
                            >
                                OK
                            </Button>
                        </div>

                    </React.Fragment>
                );
            }
            else
                card = (
                    <React.Fragment>
                        <br />
                        <p>{this.state.warningText}</p>
                    </React.Fragment>
                );

        } else {
            if (!this.state.isAdmin) {
                card = (
                    <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif", fontSize: '18px' }}>
                        <br />
                        <p>You need to be a Global Administrator to complete this step.</p>
                    </p>
                );
            } else if (!this.state.tenantConfirmed && !this.state.hasDeviceCode) {
                card = (
                    <React.Fragment>
                        <div style={{ padding:'14%' }}>
                            <img src={LoggedIn} alt="LoggedIn" style={{ height: '3.5rem' }} />
                            <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                You are logged in as
                                <br />
                                {this.state.name}({this.state.email})
                            </p>
                            
                            <p style={{ fontWeight: '500', fontFamily: "'Poppins', sans-serif", fontSize: '14px' }}>
                                Do you wish to continue setup?</p>
                        </div>
                       
                    </React.Fragment>
                );
            } else if (this.state.tenantConfirmed) {
                if (!this.state.hasDeviceCode) {
                    if (!this.state.hasSelectedFeatures) {
                        card = (
                            <React.Fragment>
                                <div className="row pb-2 text-center">
                                    <p style={{ fontSize: '18px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                        Configure your options
                                    </p>
                                </div>
                                {this.state.specificProd === 'true' ?
                                    <div className="row pb-2 text-start mb-2">
                                        <h6 style={{ fontWeight: 'bold' }}>
                                            Microsoft 365
                                        </h6>
                                        <label className="mb-2">
                                            Select the services you want to protect
                                            {/*Teams chats backup requires the use of a chargeable Microsoft API. For pricing see this <a href="https://docs.microsoft.com/en-us/graph/teams-licenses" target="_blank">Microsoft article</a>.*/}
                                        </label>

                                        <div className="ps-4 row p-0">
                                            <div className="col-4">
                                                <input type="checkbox"
                                                    checked={this.state.exchangeOnlineEnabled}
                                                    disabled={ true}
                                                /> Exchange Online
                                            </div>
                                            {/*<div className="col-8">*/}
                                            {/*    <input type="checkbox"*/}
                                            {/*        checked={this.state.sharepointOnedriveEnabled}*/}
                                            {/*        onChange={this.handleSharepointOnedriveChange}*/}
                                            {/*    /> SharePoint Online and OneDrive*/}
                                            {/*</div>*/}
                                            {/*<div className="col-4">*/}
                                            {/*    <input type="checkbox"*/}
                                            {/*        checked={this.state.microsoftTeamsEnabled}*/}
                                            {/*        onChange={this.handleMicrosoftTeamsChange}*/}
                                            {/*    /> Microsoft Teams*/}
                                            {/*</div>*/}
                                            {/*<div className="col-8">*/}
                                            {/*    <input type="checkbox"*/}
                                            {/*        checked={this.state.teamsChatEnabled}*/}
                                            {/*        onChange={this.handleTeamsChatChange}*/}
                                            {/*        disabled={!this.state.microsoftTeamsEnabled}*/}
                                            {/*    /> Enable Teams Chat API*/}
                                            {/*</div>*/}


                                        </div>
                                    </div>
                                    : <div className="row pb-2 text-start mb-2">
                                        <h6 style={{ fontWeight: 'bold', fontFamily: "'Poppins', sans-serif", fontSize: '14px', margin:'0' }}>
                                            Microsoft 365 Backup
                                        </h6>
                                        <label className="mb-2" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif", fontWeight:'500' }}>
                                            Microsoft 365 data for Mail, SharePoint, OneDrive and Teams, will be protected with CloudCover365. If you an alternative method for protecting this data, please unselect this option. Team chat required a chargeable Microsoft API. For pricing see this <a href="https://docs.microsoft.com/en-us/graph/teams-licenses" target="_blank">Microsoft article</a>.
                                        </label>
                                        <div className="" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif", display: 'flex', alignItems: 'center' }}>
                                            <input type="checkbox"
                                                checked={this.state.microsoft365Enable || this.state.tempMicrosoft365Enable}
                                                disabled={this.state.microsoft365Enable}
                                                onChange={this.handleMicrosoft365Change}
                                                id="EnableTeamsChatApi"
                                                style={{ height: '12px' }}
                                            /><label htmlFor="EnableTeamsChatApi" className="ms-1" style={{ fontSize: '13px' }}>Protect Microsoft 365 Data</label>

                                            <input type="checkbox"
                                                checked={this.state.teamsChatEnabled || this.state.tempTeamsChatEnabled}
                                                disabled={!this.state.orgExists || ((!this.state.microsoft365Enable && !this.state.tempMicrosoft365Enable) || this.state.teamsChatEnabled)}
                                                onChange={this.handleTeamsChatChange}
                                                id="EnableTeamsChatApi"
                                                style={{ height:'12px' ,marginLeft:'5px'}}
                                            /><label htmlFor="EnableTeamsChatApi" className="ms-1" style={{ fontSize: '13px' }}>Protect Teams chat data</label>
                                        </div>
                                    </div>
                                }

                                <div className="row pb-2 text-start">
                                    <h6 style={{ fontWeight: 'bold', fontFamily: "'Poppins', sans-serif", fontSize: '14px', margin: '0' }}>
                                        CloudCover Guardian for Azure
                                    </h6>
                                    <label className="mb-2" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif", fontWeight: '500' }}>
                                        To Backup objects and configurations within your Azure environment we need to create a new application in your tenancy called CCAzureBackpupRead.This application will have the required permissions to backup your environment.
                                    </label>
                                    {/*<label className="mb-2" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif", fontWeight: '500' }}>*/}
                                    {/*    This application will have the required permissions to backup your environment.*/}
                                    {/*</label>*/}
                                    <div className="" style={{ fontSize: '14px', fontFamily: "'Poppins', sans-serif", display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox"
                                            checked={this.state.azureBackUpCheck || this.state.tempAzureBackpCheck}
                                            disabled={this.state.azureBackUpCheck}
                                            onChange={this.handleAzureBackupChange}
                                            id="AddPermissionAzure"
                                            style={{ height: '12px' }}
                                        /> <label htmlFor="AddPermissionAzure" className="ms-1" style={{ fontSize: '13px' }}>Add Permission to protect Azure configuration</label>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    else {
                        card = (
                            <div style={{ padding:'12%' }}>
                                    <img src={CloudCoverPermission} alt="CloudCoverPermission" style={{ height: '3.5rem' }} />
                                    <p className="card-text" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500' }}>
                                        A CloudCover 365 application will now be registered for your
                                        organisation and granted the required permissions.
                                    </p>
                                </div>
                        );
                    }

                }

                else {
                    if ((this.state.inProgress && this.state.currentStep == 10) || (this.state.inProgress && this.state.currentStep == 11) ) {
                        card = (<div style={{ padding: '12%' }}>
                            <img src={microsoftwait} alt="microsoftwait" style={{ height: '3.5rem' }} />
                            <p className="card-text" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500' }}>
                                Waiting for Microsoft to complete the request.
                            </p>
                            <p className="card-text" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500', fontSize:'14px' }}>
                                Please keep the window open. During busy periods this can take between 10 to 15 minutes
                            </p>
                        </div>)
                    }
                    else {
                        card = (
                            <React.Fragment>
                                <div className="row pb-2 text-center">
                                    <p style={{ fontSize: '18px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                        Creating your organisation
                                    </p>
                                    <p className="mb-0 mt-2" style={{ textAlign: 'left', fontSize: '14px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                        As part of the setup you will need to authenticate with the device code below. Clicking 'copy and redirect' will automatically copy the code and take you to the authentication page.
                                    </p>
                                </div>
                                <div className="row pb-2 text-center">
                                    <div className="text-center">
                                        <div className="row">
                                            <div className="col-6 p-0">
                                                {" "}
                                                <input
                                                    type="text"
                                                    value={this.state.deviceCode}
                                                    disabled
                                                    className="form-control"
                                                    style={{ fontSize: '12px' }}
                                                />
                                            </div>
                                            <div className="col-5" style={{ textAlign: 'left' }}>
                                                <CopyToClipboard
                                                    text={this.state.deviceCode}
                                                    onCopy={() => {
                                                        this.setState({ copied: true });
                                                        window.open("https://microsoft.com/devicelogin", '_blank', 'noopener,noreferrer');

                                                    }}
                                                >
                                                    <button className="btn btn-primary" style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}>
                                                        Copy & Redirect
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                            <div className="col-10 ps-0 mt-4">
                                                <InputGroup className="p-0">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{ fontSize: '12px' }}>Email</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        value={this.state.notificationEmail}
                                                        name="notificationEmail"
                                                        type="text"
                                                        onChange={this.handleInput}
                                                        style={{ fontSize: '12px' }}
                                                    />
                                                </InputGroup>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <p className="mt-3" style={{ textAlign: 'left', fontSize: '14px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                        Please amend this address if you cannot receive emails to the account as we will be sending further instructions after setup. Select continue once completed.
                                    </p>
                                </div>
                                {/*<div className="row text-center">*/}
                                {/*    <p>*/}
                                {/*        Teams chats backup requires the use of a chargable Microsoft API. For pricing see this <a href="https://docs.microsoft.com/en-us/graph/teams-licenses" target="_blank">Microsoft article</a>.*/}
                                {/*    </p>*/}
                                {/*    <p>*/}
                                {/*        To Enable Teams chat API kindly use this <a href="https://forms.office.com/pages/responsepage.aspx?id=v4j5cvGGr0GRqy180BHbR1ax4zKyZjVBmutzKVo1pVtURTVXM0wzSldCTjVERDlYRE1WWktQRDhURCQlQCN0PWcu" target="_blank">Form</a>*/}
                                {/*    </p>*/}
                                {/*    <div style={{ fontWeight: 600 }}>*/}
                                {/*        <input*/}
                                {/*            type="checkbox"*/}
                                {/*            checked={this.state.teamsChatEnabled}*/}
                                {/*            onChange={this.handleTeamsChatChange}*/}
                                {/*            disabled={!this.state.orgExists || this.state.orgTeamsChatEnabled}*/}

                                {/*        />  Backup Teams Channel chat (cannot be enabled on initial setup.)*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </React.Fragment>
                        );
                    }
                }
                if (this.state.modernCreateAdminGroup) {
                    card = (
                        <React.Fragment>
                            <div>
                                
                                <p style={{ fontSize: '18px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Granular Access</p>
                                <div style={{ textAlign: 'left', fontSize: '15px' }}>
                                    <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>
                                        To enhance security, it's important to control who can perform specific backup and restoration operations. We can create user groups to provide this level of granular control.
                                    </p>

                                    <div>
                                        <div style={{ marginBottom: '8px' }}>
                                            <label style={{ cursor: 'pointer' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={true}
                                                    disabled={true}
                                                    onChange={(e) => this.setState({ VeeamAdmin: e.target.checked })}
                                                />
                                                <span style={{ marginLeft: '6px', fontSize: '12px', fontFamily: "'Poppins', sans-serif" }}>CloudCover365Admin - Master Administration Accounts have complete access to all functions.</span>
                                            </label>
                                        </div>
                                        <div style={{ marginBottom: '8px' }}>
                                            <label style={{ cursor: 'pointer' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.Setting}
                                                    onChange={(e) => this.setState({ Setting: e.target.checked })}
                                                />
                                                <span style={{ marginLeft: '6px', fontSize: '12px', fontFamily: "'Poppins', sans-serif" }}>CloudCover365Engineer - As above, however, they cannot maintain system settings.</span>
                                            </label>
                                        </div>
                                        <div style={{ marginBottom: '8px' }}>
                                            <label style={{ cursor: 'pointer' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.MainPage}
                                                    onChange={(e) => this.setState({ MainPage: e.target.checked })}
                                                />
                                                <span style={{ marginLeft: '6px', fontSize: '12px', fontFamily: "'Poppins', sans-serif" }}>CloudCover365JobManagement - Can only manage and monitor jobs.</span>
                                            </label>
                                        </div>
                                        <div style={{ marginBottom: '8px' }}>
                                            <label style={{ cursor: 'pointer' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.RestorePage}
                                                    onChange={(e) => this.setState({ RestorePage: e.target.checked })}
                                                />
                                                <span style={{ marginLeft: '6px', fontSize: '12px', fontFamily: "'Poppins', sans-serif" }}>CloudCover365Restore - Can only perform restore functions.</span>
                                            </label>
                                        </div>
                                        <div style={{ marginBottom: '8px' }}>
                                            <label style={{ cursor: 'pointer' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={this.state.EndUserRestore}
                                                    onChange={(e) => this.setState({ EndUserRestore: e.target.checked })}
                                                />
                                                <span style={{ marginLeft: '6px', fontSize: '12px', fontFamily: "'Poppins', sans-serif" }}>CloudCover365EndUserRecovery - Can only perform restore functions on their own Backup.</span>
                                            </label>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: '13px', fontWeight: '500', fontFamily: "'Poppins', sans-serif" }}>Please note: You will automatically be added to the CloudCover365Admin group.</p>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            }
        }
        if (this.state.orgExists && !this.state.inProgress && this.state.finished) {
            card = (
                <React.Fragment>
                    <div style={{ padding: '12%' }}>
                        <img src={setupCompleted} alt="setupCompleted" style={{ height: '3.5rem' }} />
                        <p className="card-text" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500', fontSize:'19px' }}>
                            Setup complete!
                        </p>
                        <p className="card-text" style={{ fontFamily: "'Poppins', sans-serif", fontWeight: '500', fontSize: '14px' }}>
                            Press continue to log in to the CloudCover 365 portal.
                        </p>
                    </div>
                </React.Fragment>
            );
        }
        return card;
    }

    getLeftCardButtons() {
        let buttons = null;
        if (this.state.adminCheck) {
            if (this.state.isAdmin) {
                buttons = (
                    <React.Fragment>
                        <div
                            className="row"
                            style={{
                                minWidth: "100%",
                                paddingBottom: "10px",
                            }}
                        >
                            <div
                                role="toolbar"
                                style={{
                                    justifyContent: "space-around",
                                    width: "100%",
                                }}
                            >
                                <div className="btn-group me-2 mb-1" role="group">
                                    <Button
                                        color="primary"
                                        onClick={this.confirmTenant}
                                        active={this.state.currentStep < 8}
                                        disabled={this.state.inProgress}
                                        style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                                <div className="btn-group mr-2 mb-1" role="group">
                                    <Button
                                        onClick={this.logout}
                                        disabled={this.state.inProgress}
                                        style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                    >
                                        Log Out
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
                if (this.state.tenantConfirmed) {
                    if (!this.state.hasDeviceCode) {
                        if (!this.state.hasSelectedFeatures) {
                            buttons = (
                                <React.Fragment>
                                    <div
                                        className="row"
                                        style={{
                                            minWidth: "100%",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        <div
                                            role="toolbar"
                                            style={{
                                                justifyContent: "space-around",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="btn-group me-2 mb-1" role="group">
                                                <Button
                                                    onClick={this.handleShowModal}
                                                    color="primary"
                                                    //disabled={this.state.inProgress}
                                                    style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                                >
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }
                        else {
                            buttons = (
                                <React.Fragment>
                                    <div
                                        className="row"
                                        style={{
                                            minWidth: "100%",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        <div
                                            role="toolbar"
                                            style={{
                                                justifyContent: "space-around",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="btn-group me-2 mb-1" role="group">
                                                <Button
                                                    onClick={this.getDeviceCode}
                                                    color="primary"
                                                    disabled={this.state.inProgress}
                                                    style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                                >
                                                    Login
                                                </Button>
                                            </div>
                                            <div className="btn-group mr-2 mb-1" role="group">
                                                <Button
                                                    onClick={this.logout}
                                                    disabled={this.state.inProgress}
                                                    style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                                >
                                                    Log Out
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }

                    } else if (this.state.showWarningText) {
                        buttons = (
                            <React.Fragment>
                                <div
                                    className="row"
                                    style={{
                                        minWidth: "100%",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div
                                        role="toolbar"
                                        style={{
                                            justifyContent: "space-around",
                                            width: "100%",
                                        }}
                                    >

                                        <div className="btn-group mr-2 mb-1" role="group">
                                            <Button
                                                onClick={this.logout}
                                                disabled={this.state.inProgress}
                                                style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                            >
                                                Log Out
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    else if (this.state.hasCreateAdminGroup) {
                        buttons = (
                            <React.Fragment>
                                <div
                                    className="row"
                                    style={{
                                        minWidth: "100%",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div
                                        role="toolbar"
                                        style={{
                                            justifyContent: "space-around",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="btn-group me-2 mb-1" role="group">
                                            <Button
                                                onClick={this.modernCreateAndAddAdminGroup}
                                                color="primary"
                                                disabled={(!this.state.copied || this.state.inProgress)}
                                                style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                        <div className="btn-group mr-2 mb-1" role="group">
                                            <Button
                                                onClick={this.logout}
                                                disabled={this.state.inProgress}
                                                style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                            >
                                                Log Out
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    else {
                        
                        buttons = (
                            <React.Fragment>
                                <div
                                    className="row"
                                    style={{
                                        minWidth: "100%",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div
                                        role="toolbar"
                                        style={{
                                            justifyContent: "space-around",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="btn-group me-2 mb-1" role="group">
                                            <Button
                                                onClick={this.createTennantOnVeeamDeviceCode}
                                                color="primary"
                                                disabled={(!this.state.copied || this.state.inProgress)}
                                                style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                        {/*<div className="btn-group mr-2 mb-1" role="group">*/}
                                        {/*    <Button*/}
                                        {/*        onClick={this.logout}*/}
                                        {/*        disabled={this.state.inProgress}*/}
                                        {/*    >*/}
                                        {/*        Log Out*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    if (
                        this.state.orgExists &&
                        !this.state.inProgress &&
                        this.state.finished
                    ) {
                        buttons = (
                            <React.Fragment>
                                <div
                                    className="row"
                                    style={{
                                        minWidth: "100%",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <div
                                        role="toolbar"
                                        style={{
                                            justifyContent: "space-around",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="btn-group me-2 mb-1" role="group">
                                            <a
                                                href={
                                                    "/Login?email=" + this.state.email
                                                }
                                                className="btn btn-primary"
                                                style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                            >
                                                Continue
                                            </a>
                                        </div>
                                        <div className="btn-group mr-2 mb-1" role="group">
                                            <Button
                                                onClick={this.logout}
                                                disabled={this.state.inProgress}
                                                style={{ padding: '4px 20px', fontWeight: 'bold', fontSize: '13px' }}
                                            >
                                                Log Out
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                    if (this.state.inProgress && this.state.currentStep == 10) {
                        buttons = null
                    }
                }
            }
        }
        return buttons;
    }

    getRightCard() {
        const highlightStyle = { fontSize: "large", fontWeight: "bold" };
        let card = (
            <Image style={{ width: "90%" }} src={still} alt="CloudCover 365" />
        );
        let selectStatusStatus = "";
        if (this.state.inProgress && this.state.currentStep === 8) {
            selectStatusStatus = ("active");
        } else if (this.state.currentStep === 8 && this.state.showWarningText) {
            let failIcon = <FontAwesomeIcon icon={faTimesCircle} color="red" />;
            selectStatusStatus = ("error");
        } else if (this.state.currentStep > 8) {
            selectStatusStatus = "completed";
        }
        let selectFeatureRow = (
            <div className={"step " + selectStatusStatus  }>
                <div className="content">
                    Selecting features
                </div>
                <div className="v-stepper">
            <div className="circle">
                {selectStatusStatus === "active" ? <img src={stpperLoad} style={{ marginTop: '-13px', marginLeft: '-1px', height: '16px' }} alt="stpperLoad" />
                    : selectStatusStatus === "completed" ? <img src={stepCompleted} style={{ marginTop: '-13px', marginLeft: '-1px', height: '13px' }} alt="stepCompleted" />
                    : selectStatusStatus === "error" ? <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-14px', marginLeft: '-1px', color: 'white' }} width="16" height="16" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12" /></svg>
                    : <svg style={{ marginTop: '-14px', marginLeft: '-3px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-question" viewBox="0 0 16 16">
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                        </svg>}
                                
                    </div>
                    <div className="line"></div>
                </div>
            </div>
        );

        let deviceCodeStatus = "";
        if (this.state.inProgress && this.state.currentStep == 9) {
            deviceCodeStatus = ("active");
        } else if (this.state.currentStep == 9 && this.state.showWarningText) {
            let failIcon = <FontAwesomeIcon icon={faTimesCircle} color="red" />;
            deviceCodeStatus = ("error");
        } else if (this.state.currentStep > 9) {
            deviceCodeStatus = "completed";
        }
        let gettingCodeRow = (
            //<tr style={{ padding: "0" }}>
            //    <td style={{ width: "85%", padding: "0" }}>
            //        <p style={(this.state.currentStep === 9) ? highlightStyle : { fontSize: "large" }}>Getting Device Code</p>
            //    </td>
            //    <td style={{ width: "15%", padding: "0" }}>{deviceCodeStatus}</td>
            //</tr>
            <div className={"step " + deviceCodeStatus}>
                <div className="content">
                    Accessing device code
                </div>
                <div className="v-stepper">
                    <div className="circle">
                        {deviceCodeStatus === "active" ? <img src={stpperLoad} style={{ marginTop: '-13px', marginLeft: '-1px', height: '16px' }} alt="stpperLoad" />
                            : deviceCodeStatus === "completed" ? <img src={stepCompleted} style={{ marginTop: '-13px', marginLeft: '-1px', height: '13px' }} alt="stepCompleted" />
                                : deviceCodeStatus === "error" ? <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-14px', marginLeft: '-1px', color: 'white' }} width="16" height="16" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12" /></svg>
                                    : <svg style={{ marginTop: '-14px', marginLeft: '-3px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-question" viewBox="0 0 16 16">
                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                                    </svg>}

                    </div>
                    <div className="line"></div>
                </div>
            </div>
        );

        let addingToVeeamStatus = "";
        if (this.state.inProgress && this.state.currentStep == 10) {
            addingToVeeamStatus = ("active");
        } else if (this.state.currentStep == 10 && this.state.showWarningText) {
            let failIcon = <FontAwesomeIcon icon={faTimesCircle} color="red" />;
            addingToVeeamStatus = ("error");
        } else if (this.state.currentStep > 10) {
            addingToVeeamStatus = ("completed");
        }
        let addingToVeeamRow = (
            //<tr style={{ padding: "0" }}>
            //    <td style={{ width: "85%", padding: "0" }}>
            //        <p style={this.state.currentStep === 10 ? highlightStyle : { fontSize: "large" }}>Adding Org to Veeam</p>
            //    </td>
            //    <td style={{ width: "15%", padding: "0" }}>{addingToVeeamStatus}</td>
            //</tr>

            <div className={"step " + addingToVeeamStatus}>
                <div className="content">
                    {this.state.tempMicrosoft365Enable ? "Adding Org to Veeam" : "Adding Org to Backup"}
                </div>
                <div className="v-stepper">
                    <div className="circle">
                        {addingToVeeamStatus === "active" ? <img src={stpperLoad} style={{ marginTop: '-13px', marginLeft: '-1px', height: '16px' }} alt="stpperLoad" />
                            : addingToVeeamStatus === "completed" ? <img src={stepCompleted} style={{ marginTop: '-13px', marginLeft: '-1px', height: '13px' }} alt="stepCompleted" />
                                : addingToVeeamStatus === "error" ? <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-14px', marginLeft: '-1px', color: 'white' }} width="16" height="16" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12" /></svg>
                                    : <svg style={{ marginTop: '-14px', marginLeft: '-3px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-question" viewBox="0 0 16 16">
                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                                    </svg>}

                    </div>
                    <div className="line"></div>
                </div>
            </div>
        );

        //let creatingAdminGroupStatus = "";
        //if (this.state.inProgress && this.state.currentStep == 12) {
        //    creatingAdminGroupStatus = ("active");
        //} else if (this.state.currentStep == 12 && this.state.showWarningText) {
        //    let failIcon = <FontAwesomeIcon icon={faTimesCircle} color="red" />;
        //    creatingAdminGroupStatus = ("error");
        //} else if (this.state.currentStep > 12) {
        //    creatingAdminGroupStatus = ("completed");
        //}
        //let creatingAdminGroupRow = (
        //    <div className={"step " + creatingAdminGroupStatus}>
        //        <div className="content">
        //            Creating group
        //        </div>
        //        <div className="v-stepper">
        //            <div className="circle">
        //                {creatingAdminGroupStatus === "active" ? <img src={stpperLoad} style={{ marginTop: '-13px', marginLeft: '-1px', height: '16px' }} alt="stpperLoad" />
        //                    : creatingAdminGroupStatus === "completed" ? <img src={stepCompleted} style={{ marginTop: '-13px', marginLeft: '-1px', height: '13px' }} alt="stepCompleted" />
        //                        : creatingAdminGroupStatus === "error" ? <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-14px', marginLeft: '-1px', color: 'white' }} width="16" height="16" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12" /></svg>
        //                            : <svg style={{ marginTop: '-14px', marginLeft: '-3px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-question" viewBox="0 0 16 16">
        //                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
        //                            </svg>}

        //            </div>
        //            <div className="line"></div>
        //        </div>
        //    </div>
        //);

        let addingToAdminGroupStatus = "";
        if ((this.state.inProgress && this.state.currentStep == 12 )||( this.state.inProgress && this.state.currentStep == 13)) {
            addingToAdminGroupStatus = ("active");
        } else if (this.state.currentStep == 13 && this.state.showWarningText) {
            let failIcon = <FontAwesomeIcon icon={faTimesCircle} color="red" />;
            addingToAdminGroupStatus = ("error");
        } else if (this.state.currentStep > 13) {
            addingToAdminGroupStatus = ("completed");
        }
        let addingToAdminGroupRow = (
            //<tr style={{ padding: "0" }}>
            //    <td style={{ width: "85%", padding: "0" }}>
            //        <p style={this.state.currentStep === 13 ? highlightStyle : { fontSize: "large" }}>Adding you to Admin Group</p>
            //    </td>
            //    <td style={{ width: "15%", padding: "0" }}>
            //        {addingToAdminGroupStatus}
            //    </td>
            //</tr>
            <div className={"step " + addingToAdminGroupStatus}>
                <div className="content">
                    Creating group
                </div>
                <div className="v-stepper">
                    <div className="circle">
                        {addingToAdminGroupStatus === "active" ? <img src={stpperLoad} style={{ marginTop: '-13px', marginLeft: '-1px', height: '16px' }} alt="stpperLoad" />
                            : addingToAdminGroupStatus === "completed" ? <img src={stepCompleted} style={{ marginTop: '-13px', marginLeft: '-1px', height: '13px' }} alt="stepCompleted" />
                            : addingToAdminGroupStatus === "error" ? <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-14px', marginLeft: '-1px', color: 'white' }} width="16" height="16" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12" /></svg>
                            : <svg style={{ marginTop: '-14px', marginLeft: '-3px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-question" viewBox="0 0 16 16">
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                            </svg>}

                    </div>
                    <div className="line"></div>
                </div>
            </div>
        );
        let azureBackpCheckStatus = "";
        if (this.state.inProgress && this.state.currentStep === 14) {
            azureBackpCheckStatus = ("active");
        } else if (this.state.currentStep === 14 && this.state.showWarningText) {
            let failIcon = <FontAwesomeIcon icon={faTimesCircle} color="red" />;
            azureBackpCheckStatus = ("error");
        } else if (this.state.currentStep > 14 && this.state.azureBackUpCheck) {
            azureBackpCheckStatus = "completed";
        }
        else if (this.state.currentStep > 8 && !this.state.azureBackUpCheck) {
            azureBackpCheckStatus = "notApplied";
        }
        let azureConfigureRow = (
            //<tr style={{ padding: "0" }}>
            //    <td style={{ width: "85%", padding: "0" }}>
            //        <p style={this.state.currentStep === 14 ? highlightStyle : { fontSize: "large" }}>Configure Azure Backup</p>
            //    </td>
            //    <td style={{ width: "15%", padding: "0" }}>{azureBackpCheckStatus}</td>
            //</tr>
            <div className={"step " + azureBackpCheckStatus}>
                <div className="content">
                    {this.state.tempMicrosoft365Enable ? "Azure Guardian backup" : "Configuring Azure Guardian"}
                </div>
                <div className="v-stepper">
                    <div className="circle">
                        {azureBackpCheckStatus === "active" ? <img src={stpperLoad} style={{ marginTop: '-13px', marginLeft: '-1px', height: '16px' }} alt="stpperLoad" />
                            : azureBackpCheckStatus === "completed" ? <img src={stepCompleted} style={{ marginTop: '-13px', marginLeft: '-1px', height: '13px' }} alt="stepCompleted" />
                            : azureBackpCheckStatus === "error" ? <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-14px', marginLeft: '-1px', color: 'white' }} width="16" height="16" viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6L6 18M6 6l12 12" /></svg>
                            : azureBackpCheckStatus === "notApplied" ? <svg xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-22px', marginLeft: '-6px', color: 'white' }} strokeWidth="2" width="16" height="16" fill="white" class="bi bi-dash" viewBox="0 0 10 10"><path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" /></svg>
                            : <svg style={{ marginTop: '-14px', marginLeft: '-3px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-question" viewBox="0 0 16 16">
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                            </svg>}

                    </div>
                    <div className="line"></div>
                </div>
            </div>
        );
        if (this.state.tenantConfirmed || this.state.finished) {
            card = (
                <React.Fragment>
                    <h5 className="mb-4">Setup progress</h5>
                    {selectFeatureRow}
                    {gettingCodeRow}
                    {addingToVeeamRow}
                    {/*{creatingAdminGroupRow}*/}
                    {addingToAdminGroupRow}
                    {azureConfigureRow}
                {/*<Table borderless responsive style={{ overflow: "hidden" }}>*/}
                {/*    <tbody>*/}
                {/*        {selectFeatureRow }*/}
                {/*        {gettingCodeRow}*/}
                {/*        {addingToVeeamRow}*/}
                {/*        {creatingAdminGroupRow}*/}
                {/*        {addingToAdminGroupRow}*/}
                {/*        {azureConfigureRow}*/}
                {/*    </tbody>*/}
                {/*    </Table>*/}
                </React.Fragment>
            );
        }
        return card;
    }

    confirmTenant(event) {
        event.preventDefault();
        this.setState(
            {
                inProgress: true,
                checkingTenant: true,
            },
            () => {
                if (this.state.tenantId) {
                    this.hasConsented();
                } else {
                    this.getOrgExists();
                }
            }
        );
    }

    hasConsented() {
        this.setState(
            {
                inProgress: true,
                authType: "MFA",
                currentStep: 1,
                failed: false,
            },
            () => {
                this.getOrgExists();
            }
        );
    }

    async getOrgExists() {
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        this.setState(
            {
                inProgress: true,
            },
            () => {
                const config = {
                    headers: {
                        Authorisation: `Bearer ` + tokenString,
                        isRegister: "true",
                    },
                };
                let REG_CHECK = Routes.REG_CHECK;
                if (this.state.email) {
                    REG_CHECK = Routes.REG_CHECK + '/' + this.state.email;
                }
                Axios.get(API_ROUTE + REG_CHECK, config)
                    .then((response) => {
                        let responseData = response.data;
                        let orgExists = responseData.registered;
                        if (orgExists) {
                            let GetSignUpInfo = JSON.parse(responseData.signUpData);
                            let test = responseData.orgFeatures;
                            this.setState(
                                {
                                    orgExists: orgExists,
                                    azureBackUpCheck: GetSignUpInfo.guardianEnabled,
                                    tempAzureBackpCheck: GetSignUpInfo.guardianEnabled,
                                    microsoft365Enable: GetSignUpInfo.m365Enabled,
                                    tempMicrosoft365Enable: GetSignUpInfo.m365Enabled,
                                },
                                () => {
                                    this.getSecuritySettings();
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    orgExists: orgExists,
                                },
                                () => {
                                    this.getSecuritySettings();
                                }
                            );
                        }
                    })
                    .catch((reason) => {
                        this.handleWarningMessage(true, reason.response.data, false);
                    });
            }
        );
    }

    async getSecuritySettings() {
        this.setState(
            {
                authType: "MFANOLEG",
                hasSecurity: true,
            },
            () => {
                this.getProgressModern();
            }
        );
    }

    async getProgressModern() {
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        const config = {
            headers: { Authorisation: `Bearer ` + tokenString },
        };
        Axios.get(API_ROUTE + Routes.GET_MODERN_PROGRESS, config)
            .then((response) => {
                let currentStep = response.data;
                if (this.state.orgExists) {
                    //currentStep = 9;
                    currentStep = 8;
                }
                this.setState({
                    currentStep: currentStep,
                    tenantConfirmed: true,
                    checkingTenant: false,
                    inProgress: false,
                    initialFetch: false,
                });
            })
            .catch((reason) => {
                if (reason.response) {
                    if (reason.response.data) {
                        this.handleWarningMessage(true, reason.response.data, false);
                    } else {
                        this.handleWarningMessage(true, reason.response, false);
                    }
                } else {
                    this.handleWarningMessage(true, reason, false);
                }
            });
    }
    async assignFeature(event) {
        event.preventDefault();

        //if (!this.state.tempMicrosoft365Enable && !this.state.tempAzureBackpCheck) {
        //    toast.error("Select either Protect Microsoft 365 Data or protect Azure configuration ", { autoClose: 3000 });
        //}
        //else{
            this.setState({
                inProgress: false,
                currentStep: 9,
                hasSelectedFeatures: true,
                microsoft365Enable: this.state.tempMicrosoft365Enable,
                azureBackUpCheck: this.state.tempAzureBackpCheck,
                teamsChatEnabled: this.state.tempTeamsChatEnabled
            }, () => {
                this.handleCloseModal();
            });
        //}

    }
    async getDeviceCode(event) {
        event.preventDefault();
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        if (!this.state.inProgress) {
            this.setState(
                {
                    inProgress: true,
                    showWarningText: false,
                    warningText: "",
                    currentStep: 9,
                },
                () => {
                    const config = {
                        headers: {
                            Authorisation: `Bearer ` + tokenString,
                            isRegister: "true",
                        },
                    };
                    let data = {
                        url: window.location.origin,
                    };
                    Axios.post(API_ROUTE + Routes.GET_DEVICE_CODE, data, config)
                        .then((response) => {
                            let result = response.data;
                            this.setState({
                                deviceCode: result.message,
                                //teamsChatEnabled: result.teamsChatEnabled,
                                orgTeamsChatEnabled: result.teamsChatEnabled,
                                copied: false,
                                inProgress: false,
                                currentStep: 10,
                                hasDeviceCode: true,
                            });
                        })
                        .catch((reason) => {
                            this.handleWarningMessage(true, reason.response.data, false);
                            this.setState({
                                currentStep: 9,
                            });
                        });
                }
            );
        }
    }

    async createTennantOnVeeamDeviceCode(event) {
        event.preventDefault();
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        this.setState(
            {
                inProgress: true,
                currentStep: 10,
                showWarningText: false,
                warningText: "",
            },
            () => {
                const config = {
                    headers: { Authorisation: `Bearer ` + tokenString },
                };
                let data = {
                    deviceCode: this.state.deviceCode,
                    url: window.location.origin,
                    notificationEmail: this.state.notificationEmail,
                    edit: this.state.orgExists,
                    resellerId: this.state.resellerId,
                    isExchangeOnlineEnabled: this.state.specificProd ==='true'? this.state.exchangeOnlineEnabled :true ,
                    isSharePointOneDriveEnabled: this.state.specificProd === 'true' ? this.state.sharepointOnedriveEnabled : true,
                    isMicrosoftTeamsEnabled: this.state.specificProd === 'true' ? this.state.microsoftTeamsEnabled : true,
                    isTeamsChatEnabled: this.state.teamsChatEnabled,
                    isNewApp: this.state.newApp,
                    GuardianEnabled: this.state.azureBackUpCheck,
                    M365Enabled: this.state.microsoft365Enable
                };
                Axios.post(API_ROUTE + Routes.ADD_TO_VEEAM_DEVICE_CODE, data, config)
                    .then((response) => {
                        this.setState(
                            {
                                currentStep: 11,
                            },
                            () => {
                                this.modernCreateBucket();
                            }
                        );
                    })
                    .catch((reason) => {
                        this.handleWarningMessage(true, reason.response.data, false);
                    });

            }
        );
    }

    async modernCreateBucket(event) {
        if (event) {
            event.preventDefault();
        }
        this.modernCreateAdminGroup(event);
    }

    async modernCreateAdminGroup(event) {
        if (event) {
            event.preventDefault();
        }
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        this.setState(
            {
                inProgress: false,
                currentStep: 12,
                showWarningText: false,
                warningText: "",
                hasCreateAdminGroup: true,
                modernCreateAdminGroup: true // Set state to show the UI
            },
            () => {
                const config = {
                    headers: { Authorisation: `Bearer ` + tokenString },
                };
                Axios.get(API_ROUTE + Routes.GET_AllAZURE_GROUP, config)
                    .then((response) => {
                        this.setState(
                            {
                                groups: response.data,
                            },
                            () => {
                                this.updateCheckboxes();
                            }                        
                        );
                    })
                    .catch((reason) => {
                        this.handleWarningMessage(true, reason.response.data, false);
                        this.setState({
                            hasDeviceCode: false,
                            deviceCode: "",
                        });
                    });
            }
        );
    }
    updateCheckboxes() {
        const { groups } = this.state;

        const settingsList = [
            { key: 'Setting', names: ['Veeam365Engineer', 'CloudCover365Engineer'] },
            { key: 'MainPage', names: ['Veeam365JobManagement', 'CloudCover365JobManagement'] },
            { key: 'RestorePage', names: ['Veeam365Restore', 'CloudCover365Restore'] },
            { key: 'EndUserRestore', names: ['Veeam365EndUserRecovery', 'CloudCover365EndUserRecovery'] },
        ];

        const updatedState = {};

        settingsList.forEach(setting => {
            updatedState[setting.key] = setting.names.some(name =>
                groups.some(group => group.displayName === name)
            );
        });

        this.setState(updatedState);
    }
    async modernCreateAndAddAdminGroup(event) {
        if (event) {
            event.preventDefault();
        }

        const { VeeamAdmin, Setting, MainPage, RestorePage, EndUserRestore } = this.state;
        const selectedGroups = [];
        if (VeeamAdmin) selectedGroups.push("CloudCover365Admin");
        if (Setting) selectedGroups.push("CloudCover365Engineer");
        if (MainPage) selectedGroups.push("CloudCover365JobManagement");
        if (RestorePage) selectedGroups.push("CloudCover365Restore");
        if (EndUserRestore) selectedGroups.push("CloudCover365EndUserRecovery");

        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;

        this.setState(
            {
                inProgress: true,
                currentStep: 12,
                showWarningText: false,
                warningText: "",
            },
            () => {
                const config = {
                    headers: { Authorisation: `Bearer ` + tokenString, 'Content-Type': 'application/json' },
                };
                Axios.post(API_ROUTE + Routes.CREATE_GROUP, JSON.stringify(selectedGroups), config)
                    .then((response) => {
                        this.setState(
                            {
                                currentStep: 13,
                            },
                            () => {
                                this.modernAddToAdminGroup();
                            }
                        );
                    })
                    .catch((reason) => {
                        this.handleWarningMessage(true, reason.response.data, false);
                        this.setState({
                            hasDeviceCode: false,
                            deviceCode: "",
                        });
                    });
            }
        );
    }

    async modernAddToAdminGroup(event) {
        if (event) {
            event.preventDefault();
        }
        const { VeeamAdmin, Setting, MainPage, RestorePage, EndUserRestore } = this.state;
        const selectedGroups = [];
        if (VeeamAdmin) selectedGroups.push("CloudCover365Admin");
        if (Setting) selectedGroups.push("CloudCover365Engineer");
        if (MainPage) selectedGroups.push("CloudCover365JobManagement");
        if (RestorePage) selectedGroups.push("CloudCover365Restore");
        if (EndUserRestore) selectedGroups.push("CloudCover365EndUserRecovery");
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        this.setState(
            {
                inProgress: true,
                currentStep: 13,
                showWarningText: false,
                warningText: "",
                hasCreateAdminGroup: false,
            },
            () => {
                const config = {
                    headers: { Authorisation: `Bearer ` + tokenString, 'Content-Type': 'application/json' },
                };
                Axios.post(API_ROUTE + Routes.ADD_TO_GROUP, JSON.stringify(selectedGroups), config)
                    .then((response) => {
                        if (this.state.azureBackUpCheck) {
                            this.setState({
                                currentStep: 14
                            },
                                () => {
                                    this.AddAzureBackupAccount();
                                });
                        }
                        else {
                            this.setState({
                                currentStep: 14,
                                inProgress: false,
                                orgExists: true,
                                finished: true,
                            });
                        }

                    })
                    .catch((reason) => {
                        this.handleWarningMessage(true, reason.response.data, false);
                        this.setState({
                            hasDeviceCode: false,
                            deviceCode: "",
                        });
                    });
            }
        );
    }

    async AddAzureBackupAccount(event) {
        if (event) {
            event.preventDefault();
        }
        var token = await authProvider.getAccessToken();
        var tokenString = token.accessToken;
        this.setState(
            {
                inProgress: true,
                currentStep: 14,
                showWarningText: false,
                warningText: "",
            },
            () => {
                const config = {
                    headers: { Authorisation: `Bearer ` + tokenString },
                };
                Axios.get(API_ROUTE + Routes.ADD_AZURE_ACCOUNTS, config)
                    .then((response) => {
                        this.setState({
                            currentStep: 15,
                            inProgress: false,
                            orgExists: true,
                            finished: true,
                        });
                    })
                    .catch((reason) => {
                        this.handleWarningMessage(true, reason.response.data, false);
                        this.setState({
                            hasDeviceCode: false,
                            deviceCode: "",
                        });
                    });
            }
        );
    }
    render() {
        let microsoftWarningModal = (
            <Modal
                size="xl"
                isOpen={this.state.showMicrosoftWarningPopUp}
                onClosed={this.hideMicrosoftWarningModal}
            >
                <ModalHeader>Waiting for Microsoft</ModalHeader>
                <ModalBody>
                    <p>
                        Microsoft response times are currently slow. We have been waiting
                        for your user to sync to sharepoint for the last minute. We are
                        going to try again.
                    </p>
                    <br />
                    <div
                        className="row"
                        style={{
                            position: "absolute",
                            bottom: "0",
                            paddingBottom: "15px",
                            width: "100%",
                        }}
                    >
                        <div className="col-5"></div>
                        <div className="col-4">
                            <Button color="primary" onClick={this.register}>
                                Ok
                            </Button>
                        </div>
                        <div className="col-3"></div>
                    </div>
                </ModalBody>
            </Modal>
        );
        let leftCardContent = this.getLeftCard();
        let leftCardButtons = this.getLeftCardButtons();
        let rightCardContent = this.getRightCard();
        let warningSection = [];
        if (this.state.passwordError) {
            warningSection.push(<b key="password">{this.state.passwordErrorText}</b>);
        } else if (this.state.microsoftError) {
            warningSection.push(
                <b key="microsoft">{this.state.microsoftErrorText}</b>
            );
        } else if (this.state.headerText != "") {
            warningSection.push(<b key="header">{this.state.headerText}</b>);
        } else if (this.state.inProgressForgot) {
            warningSection.push(
                <b key="forgot">Clearing VBO Account and Application from Azure.</b>
            );
        }
        let microsoftNotice = null;
        const ConfirmConfigOptions = (
            <BootstrapModal
                size="l"
                show={this.state.showModal}
                onHide={this.handleCloseModal}
            >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>Confirm your Configuration Selection</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    {this.state.tempAzureBackpCheck && this.state.tempMicrosoft365Enable ? (
                        'You have selected Azure and Microsoft 365 Backup. This will protect your Azure configurations and Microsoft 365 data, including Mail, SharePoint, OneDrive and Teams. Do you want to proceed?'
                    ) : this.state.tempAzureBackpCheck ? (
                        'You have selected Azure Backup. This will secure your Azure configurations. Do you want to proceed?'
                    ) : (
                        'You have selected Microsoft 365 Backup. This will protect your Mail, SharePoint, OneDrive and Teams data. Do you want to proceed?'
                    )}
                </BootstrapModal.Body>

                <BootstrapModal.Footer>
                    <BSButton variant="primary" onClick={this.assignFeature}>
                        Confirm
                    </BSButton>
                    <BSButton variant="secondary" onClick={this.handleCloseModal}>
                        Cancel
                    </BSButton>                   
                </BootstrapModal.Footer>
            </BootstrapModal>
        );
        //if (this.state.inProgress && this.state.currentStep == 10) {
        //    microsoftNotice = (
        //        <b>
        //            Waiting for Microsoft to complete request. This could take between 10
        //            and 15 minutes.
        //        </b>
        //    );
        //}
        return (
            <React.Fragment>
                {warningSection}
                {microsoftWarningModal}
                {ConfirmConfigOptions}
                {microsoftNotice}
                <div className="card-deck d-flex">
                    <div className="card shadow p-2 mb-3 me-3 bg-white rounded" style={{ width: this.state.currentStep >= 8 ? "70%" :"50%"}}>
                        <div className="card-body text-center" style={{ padding: "10" }}>
                            {leftCardContent}
                        </div>
                        <div
                            className="card-footer text-center"
                            style={{ backgroundColor: "white", borderTop: "none" }}
                        >
                            {leftCardButtons}
                        </div>
                        <ToastContainer />
                    </div>
                    <div className="card shadow p-1 mb-3 bg-white rounded" style={{ width: this.state.currentStep >= 8 ? "30%" : "50%" }}>
                        <div className="card-body text-center" style={{ padding: "10" }}>
                            {rightCardContent}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
