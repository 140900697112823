import { AgGridReact } from 'ag-grid-react';
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import { API_ROUTE, Routes } from "../Helpers/Constants";
import { loginAuthProvider } from "../LoginAuthProvider";
import AuthenticationContext from '../Store/Authentication-Context';
import LoaderGif from "../images/365 loading.gif";

const DownloadsModal = props => {
    const divStyle = {
        display: props.displayModal ? "block" : "none",
        zIndex: "10002"

    };
    const { setAuthContext } = useContext(AuthenticationContext);
    const [searchTerm, setSearchTerm] = useState("");
    const [gridApi, setGridApi] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    
    const urlParams = new URLSearchParams(window.location.search);
    const [orgId, setOrgId] = useState(urlParams.get("orgId"));

    const gridRef = useRef();
    const rowHeight = 25;
    const headerHeight = 35;
    function closeModal(e) {
        e.stopPropagation();
        props.closeModal(false);
    }
    const onFilterTextChange = (e) => {
        setSearchTerm(e.target.value);
        gridApi.setQuickFilter(e.target.value);
    }
    const getRowStyle = params => {
        return { fontSize: "Small" };
    };

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true
    }), []);

    const getFormattedDateTime = (params) => {
        let restoreDate = moment(params);
        let year = restoreDate.format("YYYY");
        let month = restoreDate.format("MM");
        let day = restoreDate.format("DD");
        let hours = restoreDate.format("HH");
        let mins = restoreDate.format("mm");
        let sec = restoreDate.format("ss");
        return `${year}-${month}-${day} ${hours}:${mins}:${sec}`;
    }
    function setColumnDefinition() {

        let columnDefinitions = [
            {
                field: 'key', headerName: 'Object', width: 300,
                valueFormatter: (params) => {
                    var splitValue = params.value.split('/');
                    // Customize the formatting logic here
                    return splitValue[1]; // Example: Format as currency
                },
            },
            {
                field: 'lastModified', headerName: 'Last Modified', width: 200, sort: 'desc',
                valueFormatter: (params) => {
                    return getFormattedDateTime(params.value);
                }

            },
            {
                headerName: 'Available Until', width: 150,
                valueFormatter: (params) => {
                    // Get the lastModified date from params
                    const lastModifiedDate = new Date(params.data.lastModified);

                    // Calculate 7 days after lastModifiedDate
                    const sevenDaysLater = new Date(lastModifiedDate);
                    sevenDaysLater.setDate(lastModifiedDate.getDate() + 7);

                    return getFormattedDateTime(sevenDaysLater);
              
                }

            },
            {
                field: 'size', headerName: 'Size(MB)', width: 100,
                valueFormatter: (params) => {
                    var splitValue = (parseInt(params.value) / 1048576).toFixed(3);
                    // Customize the formatting logic here
                    return splitValue; // Example: Format as currency
                },
            },
            {
                field: 'name', headerName: 'Action', cellStyle: { 'text-align': 'left' }, width: 100,
                cellRendererFramework: (params) => <div>
                    <a id={params.data.key} title="download" onClick={() => { downloadObject(params.data.key); }}>
                        <i className="fa fa-download fa-lg"></i>
                    </a> &nbsp;
                    <a id={"del" + params.data.key} title="delete" onClick={() => { deleteObject(params.data.key); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                        </svg>
                    </a> &nbsp;
                </div>
            }
        ];
        setColumnDefs(columnDefinitions);
    }

    const onGridReady = (params) => {
        var response = getRestoreDownloads(params.api);

        setGridApi(params.api);
        params.api.sizeColumnsToFit();
        //params.api.setRowData(response.data);
    }
    async function downloadObject(params) {
        gridRef.current.api.showLoadingOverlay();

        var objectKey = params.split('/');
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        let data = Axios.get(API_ROUTE + Routes.GET_DOWNLOAD_PRESIGNEDURL + "/" + objectKey[1]+"/"+orgId, config)
            .then((response) => {
                gridRef.current.api.hideOverlay();
                window.open(response.data.replace(/"/g, ''), '_blank');
            })
            .catch((reason) => {
                //console.log(reason);
            });
    }

    async function deleteObject(params) {
        gridRef.current.api.showLoadingOverlay();

        var objectKey = params.split('/');
        var tokenString = await setAuthContext("", new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        let data = Axios.get(API_ROUTE + Routes.DELETE_S3_OBJECTS + "/" + objectKey[1] + "/" + orgId, config)
            .then((response) => {
                gridRef.current.api.hideOverlay();
                getRestoreDownloads(gridRef.current.api);
               
            })
            .catch((reason) => {
                //console.log(reason);
            });
    }


    async function getRestoreDownloads(params) {
        params.showLoadingOverlay();
        var tokenString = await setAuthContext("", new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        let data = Axios.get(API_ROUTE + Routes.GET_RESTORE_DOWNLOADS + "/" + orgId, config)
            .then((response) => {
                params.setRowData(response.data);
                params.hideOverlay();
                params.sizeColumnsToFit();
            })
            .catch((reason) => {
                params.hideOverlay();
                //console.log(reason);
            });
    }

    useEffect(() => {
        setColumnDefinition();
    }, [props.refresh]);

    return (

        <div >
            <div
                style={divStyle}
                className="modal fade show"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ width: "1000px", position: "absolute", left: "6%" }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Downloads
                            </h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body" style={{ height: "400px", minWidth: "100%", width: "inherit" }}>
                            <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: "80%", borderCollapse: "collapse" }} >
                                 <div className="d-flex">
                                    <input type="search" placeholder="search..." value={searchTerm} onChange={onFilterTextChange} className="form-control form-control-sm" />
                                    <a onClick={() => getRestoreDownloads(gridApi)}><i className="fa fa-refresh" style={{ fontSize: '22px', position: 'absolute', top: '9%', right: '30px', transform: 'translateY(-50%)', cursor: 'pointer' }} ></i></a>
                                    
                                    {/*<button className="form-control">Refresh </button>*/}

                                </div>
                                {
                                    props.refresh && (
                                        <AgGridReact style={{ width: "80%" }}
                                            ref={gridRef}
                                            getRowStyle={getRowStyle}
                                            headerHeight={headerHeight}
                                            defaultColDef={defaultColDef}
                                            rowSelection="single"
                                            enableCellTextSelection="true"
                                            onGridReady={onGridReady}
                                            columnDefs={columnDefs}
                                            animateRows={true}
                                            overlayLoadingTemplate={
                                                `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><img src="${LoaderGif}" alt="loading" style="height:50px;"></div>`}
                                            overlayNoRowsTemplate={
                                                '<span className="ag-overlay-loading-center">No records to show</span>'
                                            }
                                            rowHeight={rowHeight}
                                        >
                                        </AgGridReact>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-act-control-type="modalPopupBackground" id="DownloadsModal_backgroundElement" className="Background" style={{ display: props.displayModal ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />


        </div>
    );
};

export default DownloadsModal;
