import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import { saveAs } from "file-saver";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal } from "reactstrap";
import AlertModal from "../components/MainComponents/AlertMessagePopup";
import "../CSS/general.css";
import { API_ROUTE, LEGACY_SITE, Routes } from "../Helpers/Constants";
import transpix from "../images/transpix.png";
//import Banner65 from "../images/Banner65.jpg";
import { loginAuthProvider } from "../LoginAuthProvider";
import AccountInfoModal from "./AccountInfoIframe";
import DownloadsModal from "./DownloadsModal";
import { Modal as Bootstrapodal } from "react-bootstrap";
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
//import Spinner from 'react-bootstrap/Spinner';
import decryptParameter from "../Helpers/DeCryptParameter";
import AuthenticationContext from "../Store/Authentication-Context";
import { AppContext } from "../components/MainComponents/AppContext";
import DefaultProfile from "../images/DefaultProfile.png"
import AdditionLineControlModal from "./MainComponents/AdditionLineControlPopup";
import LoaderGif from "../images/365 loading.gif";
export class NavBar extends Component {
    static displayName = NavBar.name;
    static contextType = AuthenticationContext;
    static contextType1 = AppContext;
    constructor(props) {
        super(props);
    
        const urlParams = new URLSearchParams(window.location.search);
        this.state = {
           //orgId: urlParams.get("orgId") ? decryptParameter((urlParams.get("orgId"))) : decryptParameter((localStorage.getItem("ManageOrgId"))),
           // orgName: urlParams.get("orgName") ? decryptParameter((urlParams.get("orgName"))) : decryptParameter((localStorage.getItem("ManageOrgName"))),
            
            orgId: urlParams.get("orgId") ? urlParams.get("orgId") : localStorage.getItem("ManageOrgId"),
            orgName: urlParams.get("orgName") ? urlParams.get("orgName") : localStorage.getItem("ManageOrgName"),
            impersonating: props.impersonating,
            page: props.page,
            isAdmin: false,
            isReseller: false,
            isDirectCustomer: false,
            inAdminGroup: false,
            showHelpPages: false,
            fetchedData: false,
            licensedUsersCsv: "",
            restoreLogHtml: "",
            jobListCSV: "",
            showdropdown: false,
            showadminFunctiondropdown: false,
            showAccountFunctiondropdown: false,
            showReportsdropdown: false,
            showreports: false,
            accountInfoUrl: "",
            isDisabledAccess: false,
            orgStatus: [],
            accountInfoHeader: "",
            showAccountInfo: false,
            noClose:false,
            showPriceFeature: false,
            priceFeatureInfoUrl: "",
            priceFeatureHeader: "",
            showDownloads: false,
            showAddtionLines: false,
            alertPop: false,
            alertMessage: [],
            priceModelName: "",
            priceModelTerm:"",
            isRestoreDownloadRefreshed: false,
            isAzureGuardianOnly: props.isAzureGuardianOnly !== undefined ? props.isAzureGuardianOnly : true
        };
        this.checkDisabledAccess();
        this.showHelpPages = this.showHelpPages.bind(this);
        this.getLicensedUsers = this.getLicensedUsers.bind(this);
        this.protectionReport = this.protectionReport.bind(this);
        this.jobListAudit = this.jobListAudit.bind(this);
        this.csvLinkRef = React.createRef();
        this.htmlLinkRL = React.createRef();
        this.hideHelpPages = this.hideHelpPages.bind(this);
        this.getSettings = this.getSettings.bind(this);
        this.hideAccountInfoIframe = this.hideAccountInfoIframe.bind(this);
        this.cancelAlertClick = this.cancelAlertClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getSettings();
        //.then(() => {
           
          /*  const [, , navBarSetting, setNavBarSetting] = contextType1;

            setNavBarSetting(
                prevNavBarSetting => ({
                    ...prevNavBarSetting,
                    isAdmin: this.state.isAdmin,
                    isReseller: this.state.isReseller,
                    isDirectCustomer: this.state.isDirectCustomer,
                    inAdminGroup: this.state.inAdminGroup,

                    fetchedData: this.state.fetchedData,
                    licensedUsersCsv: this.state.licensedUsersCsv,
                    restoreLogCsv: this.state.restoreLogCsv,

                    orgStatus: this.state.orgStatus,

                    priceModelName: this.state.priceModelName,
                    orgId: this.state.orgId,
                    orgName: this.state.orgName,
                }));
           
        });*/
        
        this.hideDownloads = this.hideDownloads.bind(this);
    }


   

    componentDidMount() {
        window.addEventListener('message', this.handleMessage);
       
        /*this.setState({
            orgId: localStorage.getItem("ManageOrgId") ? localStorage.getItem("ManageOrgId") : new URLSearchParams(window.location.search).get("orgId"),
            orgName: localStorage.getItem("ManageOrgName") ? localStorage.getItem("ManageOrgName") : new URLSearchParams(window.location.search).get("orgName"),

        });*/

    }
    componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
    }
    handleMessage = (event) => {

        const iframe = document.getElementById('AccountInfoId');
        if (event.source === iframe.contentWindow && event.data === 'changeUrl') {
            this.setState({
                showAccountInfo: false,
                priceFeatureHeader: "Price Model",
                priceFeatureInfoUrl: "/PricingModel?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName + "&resellerOnly=false&displayOnly=false&collapsed=false",
                showPriceFeature: true
            });
        }
    };
    async getSettings() {
        if (!this.state.fetchedData) {
       
            const authContext = this.props.authContext || this.context;
            const { setAuthContext } = authContext;
            var tokenString = await setAuthContext(new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                },
            };
            await Axios.get(API_ROUTE + Routes.GET_NAVBAR_SETTINGS, config)
                .then((response) => {
                    let settings = response.data;
                    this.setState({
                        isAdmin: settings.isAdmin,
                        isReseller: settings.isReseller,
                        fetchedData: true,
                        inAdminGroup: settings.inAdminGroup,
                        isDirectCustomer: settings.isDirectCustomer,
                    });
                    {
                        const config = {
                            headers: {
                                Authorisation: `Bearer ` + tokenString,
                            },
                        };
                        Axios.get(API_ROUTE + Routes.PRICING_MODEL_ID + "/" + this.state.orgId + "/dummyValue", config)
                            .then((response) => {
                                if (response.data !== "" && response.data !== "Failed") {
                                    let selectItems = JSON.parse(response.data);
                                    Axios.get(API_ROUTE + Routes.GET_ORG_STATUS + "/" + this.state.orgId, config)
                                        .then((response) => {
                                            if (response.data.status === 'Failed' || this.state.isDisabledAccess == true) {

                                                if (!settings.isAdmin && this.state.page === "main") {


                                                    this.setState({
                                                        orgStatus: response.data,
                                                        priceModelTerm: response.data.termValue,
                                                        accountInfoHeader: "Account Management",
                                                        accountInfoUrl: "/ContractCommit?signkey=" + this.state.orgId + "&reseller=" + this.state.isReseller + "&directCustomer=" + this.state.isDirectCustomer + "&status=Failed&userPrice=" + response.data.userPrice + "&priceName=" + selectItems.price.name + "&quotaValue=" + response.data.quotaValue + "&termValue=" + response.data.termValue + "&isManaged=" + (this.state.orgId !== localStorage.getItem("orgId")),
                                                        showAccountInfo: true,
                                                    });

                                                } else {
                                                    this.setState({
                                                        orgStatus: response.data, priceModelTerm: response.data.termValue,
                                                        //accountInfoHeader: "Account Management",
                                                        //accountInfoUrl: "/ContractCommit?signkey=" + this.state.orgId + "&reseller=" + this.state.isReseller + "&directCustomer=" + this.state.isDirectCustomer + "&status=Failed&userPrice=" + response.data.userPrice + "&priceName=" + this.state.priceModelName + "&quotaValue=" + response.data.quotaValue,
                                                        //showAccountInfo: true,
                                                    });
                                                }

                                            }

                                            this.setState({ orgStatus: response.data, priceModelTerm: response.data.termValue, });
                                            // CheckForEntraBackup();

                                        })
                                        .catch((reason) => {
                                            console.log("Error: " + reason)

                                        });
                                    this.setState({
                                        priceModelName: selectItems.price.name
                                    });
                                }
                            })
                            .catch((reason) => {
                            });


                       
                    }
                })
                .catch((reason) => {
                    console.log("catch" + reason);
                });
        }

    }

    async checkDisabledAccess() {
        
        if (localStorage.getItem("DisabledAccess") === "true") {
           
            const authContext = this.props.authContext || this.context;
            const { setAuthContext } = authContext;
            var tokenString = await setAuthContext(new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,

                },
            };
            let data = {
                OrgId: this.state.orgId,
                Setting: "DisableAccess",
            };

            await Axios.post(API_ROUTE + Routes.GET_SET_SETTINGS, data, config)
                .then((response) => {
                    if (response.data.result) {
                      
                        if (response.data.result === "True") {
                            this.setState({

                               isDisabledAccess:true,
                              noClose: true
                            });
                        
                        }
                        else {
                            this.setState({

                                isDisabledAccess: false,
                                noClose: false,
                            });
                        }
                        
                        }

                    
                    else {
                        this.setState({

                            isDisabledAccess: false,
                            noClose: false,
                        });
                    }
                        

                })
                .catch((reason) => {
                    this.setState({ restoreMailTo: "0" });
                });

            
        }
    }
    hideAccountInfoIframe() {
        this.setState({
            accountInfoUrl: "", 
            showAccountInfo: false
        });
    }
    hideDownloads() {
        this.setState({
            showDownloads: false,
            isRestoreDownloadRefreshed: false
        });
    }

    closeModal() {
     
        this.setState({
            showAddtionLines: false
        });
    }

    cancelAlertClick() {
        this.setState({
            alertPop: false
        });
    }

    showHelpPages(event) {
        event.preventDefault();
        this.setState({
            showHelpPages: true,
        });
    }
    async getLicensedUsers(e) {
        e.preventDefault();
        let toastId = toast("Licensed User list will be available in downloads section once ready", { autoClose: false });
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        Axios.get(API_ROUTE + Routes.GET_LICENSED_USERS + "/" + this.state.orgId, config)
            .then((response) => {
                let resp = response.data;
                toast.dismiss(toastId);

            })
            .catch((reason) => {
                toast.dismiss(toastId);



            });
    }
  
    async runInvoiceProcess(e) {
        // e.preventDefault();
        const ToastContent = ({ message }) => (
            <div dangerouslySetInnerHTML={{ __html: message }} />
        );
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        Axios.get(API_ROUTE + Routes.RUN_INVOICE_PROCESS, config)
            .then((response) => {
                let resp = response.data;

                if (response.data.result) {
                    let toastId = toast(<ToastContent message={response.data.result} />, { autoClose: false });
                   // toast.dismiss(toastId);
                }

            })
            .catch((reason) => {




            });
    }
    async runInvoiceBatch(e) {
        // e.preventDefault();
        const ToastContent = ({ message }) => (
            <div dangerouslySetInnerHTML={{ __html: message }} />
        );
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        Axios.get(API_ROUTE + Routes.RUN_INVOICE_BATCH, config)
            .then((response) => {
                let resp = response.data;

                if (response.data.result) {
                    let toastId = toast(<ToastContent message={response.data.result} />, { autoClose: false });
                   // toast.dismiss(toastId);
                }

            })
            .catch((reason) => {




            });
    }

    async proxyTest(userName) {
        //e.preventDefault();
        const ToastContent = ({ message }) => (
            <div dangerouslySetInnerHTML={{ __html: message }} />
        );
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        Axios.post(API_ROUTE + Routes.PROXY_TEST, { userName: userName }, config)
            .then((response) => {
                let resp = response.data;

                if (response.data.result) {
                    let toastId = toast(<ToastContent message={response.data.result} />, { autoClose: false });
                   // toast.dismiss(toastId);
                }

            })
            .catch((reason) => {




            });
    }
    async runBatch(userName) {
        //e.preventDefault();
        const ToastContent = ({ message }) => (
            <div dangerouslySetInnerHTML={{ __html: message }} />
        );
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        Axios.post(API_ROUTE + Routes.RUN_BATCH, { userName: userName }, config)
            .then((response) => {
                let resp = response.data;

                if (response.data.result) {
                    let toastId = toast(<ToastContent message={response.data.result} />, { autoClose: false });
                   // toast.dismiss(toastId);
                }

            })
            .catch((reason) => {




            });
    }

    hideHelpPages(event) {
        event.preventDefault();
        this.setState({
            showHelpPages: false,
        });
    }

    async protectionReport(e) {
        e.preventDefault();
        let toastId = toast("Protection Report will be available in downloads section once ready. This may take up to 15 minutes depending on the size of your organisation.", { autoClose: false });
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,

            },
            responseType: 'blob',
        };
        Axios.get(API_ROUTE + Routes.GET_PROTECTION_REPORT + "/" + this.state.orgId, config)
            .then((response) => {
                var blob = new Blob([response.data], {
                    type: "application/pdf",
                });
                toast.dismiss(toastId);

            })
            .catch((reason) => {
                //toast.dismiss(toastId);
                //this.setState({
                //    alertPop: true,
                //    alertMessage: {
                //        header: "Error",
                //        message: reason.response.data.message,
                //    },
                //});
                console.log("Protection report error");

            });
    }

    async restoreAudit(e) {
        e.preventDefault();
        let toastId = toast("Downloading Restore Session Audit Report. This may take up to 15 minutes depending on the size of your organisation.", { autoClose: false });
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        Axios.get(API_ROUTE + Routes.GET_RESTORESESSION_REPORT + "/" + this.state.orgId, config)
            .then((response) => {
                let resp = response.data.replace('"', '');
                toast.dismiss(toastId);
                resp = resp.replaceAll('"', ' ')
                this.setState({ restoreLogHtml: resp }, () => {
                    const blob = new Blob([this.state.restoreLogHtml], { type: 'text/html' });
                    const url = URL.createObjectURL(blob);
                    const newTab = window.open(url, '_blank');
                    URL.revokeObjectURL(url);
                });
            })
            .catch((reason) => {
                toast.dismiss(toastId);
            });
    }
    
    async jobListAudit(e) {
        e.preventDefault();
        let toastId = toast("Downloading Jobs List Audit Report. This may take up to few minutes depending on the numbers of jobs available", { autoClose: false });
        const authContext = this.props.authContext || this.context;
        const { setAuthContext } = authContext;
        var tokenString = await setAuthContext(new Date());

        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };

        let payload = {
            type: "OrgJobList",
            orgs: [
                {
                    orgName: this.state.orgName,
                    orgId: this.state.orgId
                }
            ]
        };
        let object = null;
        if (this.state.page === "masterAdmin" || this.state.page === "eventlogs" || this.state.page === "jobmanagement")
        {
            payload.type = "MasterAdminJobList";
            object = JSON.parse(decryptParameter(localStorage.getItem("ResellerOrgs")));
        }
        else if (this.state.page === "reseller")
        {
            payload.type = "ResellerJobList";
            object = JSON.parse(decryptParameter(localStorage.getItem("ResellerOrganisation")));
        }
  

        if (object) {
            payload.orgs = object.map(item => ({
                orgName: item.orgName,
                orgId: item.orgId
            }));
        }
        

        await Axios.post(API_ROUTE + Routes.GET_ALL_JOBS_STATUS, payload, config)
            .then((response) => {
                                    let csvData = response.data;
                                    toast.dismiss(toastId);
                                    this.setState({ jobListCSV: csvData }, () => {
                                        this.csvLinkRef.current.link.click();
                                    });
                                })
            .catch((reason) => {
                toast.dismiss(toastId);
            });
        
    }
        
    render() {
        const isVeeam365Engineer = localStorage.getItem("IsVeeam365Engineer") === "true";
        const isVeeam365JobManagement = localStorage.getItem("IsVeeam365JobManagement") === "true";
        const isVeeam365Restore = localStorage.getItem("IsVeeam365Restore") === "true";
        const isVeeam365EndUserRestore = localStorage.getItem("IsDirectCustomer") === "true";
        let helpModal = null;
        if (this.state.showHelpPages) {
            helpModal = (
                <Modal
                    className="help-modal"
                    size="xl"
                    isOpen={this.state.showHelpPages}
                    onClosed={this.hideHelpPages}
                >
                    <table
                        id="HelpPanel"
                        style={{ border: "outset", backgroundColor: "#FFFFFF" }}
                    >
                        <tr
                            id="PopupHeader"
                            style={{ height: "36px", backgroundColor: "lightgray" }}
                        >
                            <td style={{ textAlign: "left", fontWeight: "500" }}>
                                CloudCover365 - Help
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <Button onClick={this.hideHelpPages}>X</Button>
                            </td>
                        </tr>
                        <tr className="PopupBody">
                            <td colspan="2">
                                <iframe
                                    id="HelpPopupFrame"
                                    scrolling="auto"
                                    style={{
                                        width: "1000px",
                                        height: "600px",
                                        border: "hidden",
                                        overflow: "no-display"
                                    }}
                                    src={this.state.page == "main" ? "https://cloudcover365.virtualdcs.co.uk/helpme/BackupManagement.html" : "https://cloudcover365.virtualdcs.co.uk/helpme/BackupRestores.html"}
                                    runat="server"
                                >
                                </iframe>
                            </td>
                        </tr>
                    </table>
                </Modal>
            );
        }

        let navButtons = [];

        if ((this.state.isReseller && this.state.inAdminGroup && !isVeeam365JobManagement && !isVeeam365Restore) && this.state.page !== "settings") {
            // master on us reseller on reseller
            let link = "reseller";

            let orgsButton = (
                <li key="organisations" className="nav-item">
                    {this.state.page !== "masterAdmin" && this.state.page !== "reseller" ?
                        <a className="nav-link"
                            href={this.state.isAdmin ? "/masteradmin?orgId=" + localStorage.getItem("orgId") + "&orgName=" + localStorage.getItem("orgName") : "/reseller?orgId=" + localStorage.getItem("orgId") + "&orgName=" + localStorage.getItem("orgName")}//from whee it is called will query parameter be there
                            style={{ fontWeight: "normal" }}>
                            Organisations
                        </a> :
                        <span className="nav-link" style={{ fontWeight: this.state.page === "masterAdmin" || this.state.page === "reseller" ? "bold" : "normal" }}>
                            Organisations
                        </span>}
                </li>
            );
            navButtons.push(orgsButton);
        }

        if ((this.state.page !== "jobmanagement" && this.state.page !== "eventlogs" && this.state.page !== "bucket" && this.state.page !== "masterAdmin" && this.state.page !== "reseller") && !isVeeam365Restore && !isVeeam365EndUserRestore ) {
            let adminButton = (
                <li key="admin" className="nav-item" defaultChecked={this.state.page == "main"}>
                    <a className="nav-link"
                        style={{ fontWeight: this.state.page === "main" ? "bold" : "normal" }}

                        href={"/main?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName}>

                        Admin

                    </a>
                </li>
            );
            navButtons.push(adminButton);
        }

        if (this.state.page === "masterAdmin" || this.state.page === "eventlogs" || this.state.page === "jobmanagement") {


            let resellerid = decryptParameter((localStorage.getItem("ResellerID")));
            let isAdmin = decryptParameter((localStorage.getItem("IsAdmin")));
            let userName = decryptParameter((localStorage.getItem("UserName")));
            

            //let cloudconnectButton = (
            //    <li key="cloudconnect" className="nav-item" defaultChecked={this.state.page === "masterAdmin"}>
            //        <a className="nav-link"
            //            style={{ fontWeight: this.state.page === "main" ? "bold" : "normal" }}

            //            href={LEGACY_SITE + "CloudConnectList.aspx?userName=" + userName + "&isAdmin=" + isAdmin + "&resellerID=" + resellerid}>

            //            Cloud Connect

            //        </a>
            //    </li>
            //);
            //navButtons.push(cloudconnectButton);


            let invoiceButton = (
                <li key="invoices" className="nav-item">
                    <a className="nav-link"
                        style={{ fontWeight: this.state.page === "main" ? "bold" : "normal" }}
                    onClick={(e) => {
                        this.setState({
                            accountInfoHeader: "Invoicing",
                            accountInfoUrl: "/Invoice?isAdmin=true"  ,
                            showAccountInfo: true
                        });
                    }
                    }
                    >

                        Invoices
                    </a>
                </li>
            );

            navButtons.push(invoiceButton);

            let adminFunctionsButton = (
                <Dropdown
                    key="rootAdminFunction"
                    value="rootrootAdminFunctionOpen"
                    isOpen={this.state.showadminFunctiondropdown}
                    toggle={(e) => { this.setState(prevState => ({ showadminFunctiondropdown: !prevState.showadminFunctiondropdown })); }}
                    className="mr-2"
                >
                    <DropdownToggle caret style={{ maxWidth: "150px", backgroundColor: "#f8f9fa", color: "rgba( 0,0,0 , 0.6)", borderColor: "#f8f9fa", fontWeight: this.state.page === "eventlogs" || this.state.page === "jobmanagement" ? "bold" : "normal" }}>
                        Admin Functions
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem style={{ fontWeight: this.state.page === "eventlogs" ? "bold" : "normal" } }
                            onClick={(e) => {
                                window.location.href = "/EventLogs?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName;

                            }
                            }
                        >
                            Event View
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {
                                window.location.href = LEGACY_SITE + "CloudConnectList.aspx?userName= " + userName + "&isAdmin=" + isAdmin + "&resellerID=" + resellerid;

                            }
                            }
                        >
                            Veeam Cloud Connect
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {

                                this.runBatch(userName);
                            }
                            }
                        >
                            Manually run batches
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {

                                this.runInvoiceBatch();
                            }
                            }
                        >
                            Create invoices
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {

                                this.runInvoiceProcess();
                            }
                            }
                        >
                            Create and Process Invoices
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {

                                this.proxyTest();
                            }
                            }
                        >
                            Refresh Proxy List
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {
                                this.setState({
                                    accountInfoHeader: "Stranded Bucket List",
                                    
                                    accountInfoUrl:  "/Bucket",
                                    showAccountInfo: true
                                });
                            }
                            }
                        >
                            Stranded Bucket List
                        </DropdownItem>

                        <DropdownItem style={{ fontWeight: this.state.page === "jobmanagement" ? "bold" : "normal" }}
                            onClick={(e) => {
                                window.location.href = "/JobManagement?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName;
                            }
                            }
                        >
                            Job Monitoring
                        </DropdownItem>

                        <DropdownItem
                            onClick={(e) => {
                                this.setState({
                                  
                                    showAddtionLines: true
                                });
                            }
                            }
                        >
                            ConnectWise-Maintenance
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            );

            navButtons.push(adminFunctionsButton);

        }
        if (
            !isVeeam365JobManagement && ((this.state.inAdminGroup || isVeeam365Engineer || isVeeam365Restore || isVeeam365EndUserRestore) && (this.state.page === "main" || this.state.page === "restore" ||  this.state.page === "peoplehrtoxero" || this.state.page === "settings" ))
        ) {
            let restoreButton = (
                <li key="restore" className="nav-item">
                    <a className="nav-link"
                        style={{ fontWeight: this.state.page === "restore" ? "bold" : "normal" }}
                        href={"/Restore?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName}>
                        Restore
                    </a>
                </li>
            );
            navButtons.push(restoreButton);
        }
        
        if (this.state.page !== "restore" && this.state.page !== "masterAdmin" && (this.state.isDirectCustomer || this.state.page === "reseller")) {

            let resellerid = decryptParameter((localStorage.getItem("ResellerID")));
            let packageDropdownItem = null;
                packageDropdownItem = (<DropdownItem
                    onClick={(e) => {
                        this.setState({
                            priceFeatureHeader: "Price Model",
                            priceFeatureInfoUrl: "/PricingModel?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName + "&resellerOnly=false&displayOnly=false&collapsed=false",
                            showPriceFeature: true
                        });
                    }
                    }
                >
                    Package
                </DropdownItem >);
            let isMasterAdmin = (this.state.orgId === localStorage.getItem("orgId") && localStorage.getItem("ResellerID") === "1");
     
            let accountButton = (
                <Dropdown
                    key="root"
                    value="rootRestoreOpen"
                    isOpen={this.state.showdropdown}
                    toggle={(e) => { this.setState(prevState => ({ showdropdown: !prevState.showdropdown })); }}
                    className="mr-2"
                >
                    <DropdownToggle caret style={{ maxWidth: "150px", backgroundColor: "#f8f9fa", color: "#000000a6", borderColor: "#f8f9fa" }}>
                        Account Info
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            onClick={(e) => {
                                this.setState({
                                    accountInfoHeader: "Invoicing",

                                    accountInfoUrl: "/Invoice?isAdmin=" + isMasterAdmin + "&orgId=" + this.state.orgId,

                                    showAccountInfo: true
                                });
                            }
                            }
                        >
                            Invoices
                        </DropdownItem>
                        <DropdownItem
                            onClick={(e) => {
                                this.setState({
                                    accountInfoHeader: "Account Management",
                                    accountInfoUrl: "/ContractCommit?signkey=" + this.state.orgId + "&reseller=" + this.state.isReseller + "&directCustomer=" + this.state.isDirectCustomer + "&status=" + this.state.orgStatus.status + "&userPrice=" + this.state.orgStatus.userPrice + "&priceName=" + this.state.priceModelName + "&quotaValue=" + this.state.orgStatus.quotaValue + "&termValue=" + this.state.priceModelTerm + "&isManaged=" + (this.state.orgId !== localStorage.getItem("orgId")),
                                    showAccountInfo: true
                                });
                            }
                            }
                        >
                            Direct Debit
                        </DropdownItem>

                        {packageDropdownItem}
                    </DropdownMenu>
                </Dropdown>
            );
           
            if (!this.props.isAzureGuardianOnly && ((this.state.page === "settings" && resellerid === "1") || (this.state.page !== "settings"))) {
                navButtons.push(accountButton);
            }
        }

        if (!this.props.isAzureGuardianOnly && (this.state.page === "reseller" ||
            this.state.page === "masterAdmin" || this.state.page === "jobmanagement" || this.state.page === "eventlogs" ||
            (!isVeeam365JobManagement && (this.state.page === "main") ))) {
            let reportsDropdownButton = (
                <Dropdown
                    key="reports"
                    value="rootReportOpen"
                    isOpen={this.state.showReportsdropdown}
                    toggle={(e) => { this.setState(prevState => ({ showReportsdropdown: !prevState.showReportsdropdown })); }}
                    className="mr-2"
                >
                    <DropdownToggle caret style={{ maxWidth: "150px", backgroundColor: "#f8f9fa", color: "#000000a6", borderColor: "#f8f9fa" }}>
                        Reports
                    </DropdownToggle>
                    <DropdownMenu>
                        {this.state.page === "main" ? 
                        <>
                            <DropdownItem onClick={this.protectionReport}>
                                Protection Report
                            </DropdownItem>
                            <DropdownItem onClick={this.getLicensedUsers}>
                                Licenced Users
                            </DropdownItem>
                            <DropdownItem
                                onClick={(e) => {
                                    this.restoreAudit(e)
                                    }
                                }
                            >
                                Restore Log

                            </DropdownItem> 
                            </> : null}
                        {this.state.page === "reseller" ||
                         this.state.page === "masterAdmin" || this.state.page === "jobmanagement" || this.state.page === "eventlogs" ?
                            <>
                                <DropdownItem
                                    onClick={(e) => {
                                        this.jobListAudit(e)
                                    }
                                    }
                                >

                                    JobList Log

                                </DropdownItem>
                            </> : null}


                    </DropdownMenu>
                </Dropdown >

            );
            navButtons.push(reportsDropdownButton);
        }

        if (!isVeeam365JobManagement && ( this.state.page == "restore" || this.state.page == "peoplehrtoxero" || this.state.page == "main" )) {
            let downloadButton = (
                <li key="download" className="nav-item">
                    <a className="nav-link"
                        style={{ fontWeight: "normal" }}
                        onClick={(e) => {
                            this.setState({
                                showDownloads: true,
                                isRestoreDownloadRefreshed: true
                            });
                        }} >
                        Downloads
                    </a>
                </li>
            );
            navButtons.push(downloadButton);
        }

        if (!this.props.isAzureGuardianOnly && ( (this.state.page === "main" || this.state.page === "settings") && !isVeeam365Engineer && !isVeeam365JobManagement && !isVeeam365Restore)) {
            let settingButton = (
                <li key="settings" className="nav-item">

                    {this.state.page !== "settings" ?
                        <a className="nav-link" href={"/settings?orgId=" + this.state.orgId + "&orgName=" + this.state.orgName}>Settings</a>:
                        <span className="nav-link" style={{ fontWeight:  "bold" }}>
                            Settings
                        </span>}

                    
                </li>
            );
            navButtons.push(settingButton);

        }
        if (!this.props.isAzureGuardianOnly) {
            let helpButton = (
                <li key="help" className="nav-item">
                    <a className="nav-link" href={null} onClick={this.showHelpPages}>
                        Help
                    </a>
                </li>
            );
            navButtons.push(helpButton);
        }
        
        

        if (!this.state.fetchedData) {
            navButtons = [];
        }

        return (
            <React.Fragment>
                {helpModal}
                <nav className="navbar navbar-expand-lg navbar-light bg-light custStyle MenuFont titlebar">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="navbar-brand">
                            <div className="menulogo align-top  m-0 ms-2 mb-1 mt-1" alt="" ></div>
                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                {navButtons}
                            </ul>

                            <div style={{ position: 'absolute', right: '15px', display: 'flex', alignItems: 'center' }} className="d-none d-xxl-flex">
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                                        <div style={{ fontSize: "15px", color: "grey" }}>{localStorage.getItem('UserDisplayName')}</div>
                                        <div style={{ fontSize: "15px", color: "grey" }}>{localStorage.getItem('UserEmailID')}</div>
                                </div>
                                <BootstrapDropdown
                                    key="userAccountFunction"
                                    value="useruserAccountFunctionOpen"
                                    isOpen={this.state.showAccountFunctiondropdown}
                                    toggle={(e) => { this.setState(prevState => ({ showAccountFunctiondropdown: !prevState.showAccountFunctiondropdown })); }}
                                    className="mr-2"
                                >
                                    <BootstrapDropdown.Toggle id="user-dropdown" className="p-0">
                                        <div style={{ backgroundColor: "white" }}>
                                            {localStorage.getItem('Photo') && localStorage.getItem('Photo') !== 'null' ? (
                                                <div style={{ borderRadius: '50%', overflow: 'hidden', border: '2px solid #fff' }}>
                                                    <img src={`data:image/png;base64,${localStorage.getItem('Photo')}`} alt="User profile" style={{ width: '45px', height: '45px', objectFit: 'cover' }} />
                                                </div>
                                            ) : localStorage.getItem('Photo') === 'null' ? (
                                                <div style={{ borderRadius: '50%', overflow: 'hidden', border: '2px solid #fff' }}>
                                                    <img src={DefaultProfile} style={{ width: '45px', height: '45px', objectFit: 'cover' }} alt="User profile" />
                                                </div>
                                            ) : (
                                                <div style={{ borderRadius: '50%', overflow: 'hidden', border: '2px solid #fff' }}>
                                                    <img src={DefaultProfile} style={{ width: '45px', height: '45px', objectFit: 'cover' }} alt="User profile" />
                                                </div>
                                            )}
                                        </div>
                                    </BootstrapDropdown.Toggle>
                                    <BootstrapDropdown.Menu >
                                        <BootstrapDropdown.Item href="#" onClick={(e) => { window.location.href = "/Logout"; }}>Log out</BootstrapDropdown.Item>
                                    </BootstrapDropdown.Menu>
                                </BootstrapDropdown>

                            </div>
                            <div style={{ position: 'absolute', right: '15px', display: 'flex', alignItems: 'center' }} className="d-xxl-none">
                                <BootstrapDropdown
                                    key="userAccountFunction"
                                    value="useruserAccountFunctionOpen"
                                    isOpen={this.state.showAccountFunctiondropdown}
                                    toggle={(e) => { this.setState(prevState => ({ showAccountFunctiondropdown: !prevState.showAccountFunctiondropdown })); }}
                                >
                                    <BootstrapDropdown.Toggle id="user-dropdown" className="p-0">
                                        <div style={{ backgroundColor: "white" }}>
                                            {localStorage.getItem('Photo') && localStorage.getItem('Photo') !== 'null' ? (
                                                <div style={{ borderRadius: '50%', overflow: 'hidden', border: '2px solid #fff' }}>
                                                    <img src={`data:image/png;base64,${localStorage.getItem('Photo')}`} alt="User profile" style={{ width: '45px', height: '45px', objectFit: 'cover' }} />
                                                </div>
                                            ) : localStorage.getItem('Photo') === 'null' ? (
                                                <div style={{ borderRadius: '50%', overflow: 'hidden', border: '2px solid #fff' }}>
                                                    <img src={DefaultProfile} style={{ width: '45px', height: '45px', objectFit: 'cover' }} alt="User profile" />
                                                </div>
                                            ) : (
                                                <div style={{ borderRadius: '50%', overflow: 'hidden', border: '2px solid #fff' }}>
                                                    <img src={DefaultProfile} style={{ width: '45px', height: '45px', objectFit: 'cover' }} alt="User profile"  />
                                                </div>
                                            )}
                                        </div>
                                    </BootstrapDropdown.Toggle>

                                    <BootstrapDropdown.Menu>
                                        <BootstrapDropdown.Item href="#" onClick={(e) => { e.preventDefault(); }}>{localStorage.getItem('UserDisplayName')}</BootstrapDropdown.Item>
                                        <BootstrapDropdown.Item href="#" onClick={(e) => { e.preventDefault(); }}>{localStorage.getItem('UserEmailID')}</BootstrapDropdown.Item>
                                        <BootstrapDropdown.Divider />
                                        <BootstrapDropdown.Item href="#" onClick={(e) => { window.location.href = "/Logout"; }}>Log out</BootstrapDropdown.Item>
                                    </BootstrapDropdown.Menu>
                                </BootstrapDropdown>
                            </div>
                        </div>
                    </div>
                    <ToastContainer limit={4} style={{ fontSize: "small" }} />
                    <CSVLink className="nav-link" filename="RestoreLog.html" data={this.state.restoreLogHtml} ref={this.htmlLinkRL}></CSVLink>
                    <CSVLink className="nav-link" filename="JobsList.csv" data={this.state.jobListCSV} ref={this.csvLinkRef}></CSVLink>
                    <AccountInfoModal displayModal={this.state.showAccountInfo} noClose={((this.state.isReseller && this.state.orgId === localStorage.getItem("orgId") )&& this.state.page !== "reseller" && !this.state.isAdmin && this.state.orgStatus.status === "Failed") || this.state.noClose} closeModal={this.hideAccountInfoIframe} action={this.state.accountInfoHeader} url={this.state.accountInfoUrl} />
                    <Bootstrapodal
                        size="xl"
                        show={this.state.showPriceFeature}
                        onHide={(e) => {
                            window.location.reload();
                            this.setState({
                                showPriceFeature: false
                            });
                        }}
                        backdrop="static"
                        aria-labelledby="main-popup"
                        centered
                        className="PriceFeature-modal"
                    >
                        <Bootstrapodal.Header closeButton>
                            <Bootstrapodal.Title id="main-popup">
                                {this.state.priceFeatureHeader}
                            </Bootstrapodal.Title>
                        </Bootstrapodal.Header>
                        <Bootstrapodal.Body className="p-0">

                            {this.state.showPriceFeature ? <iframe id="AccountPriceFeatureId"
                                src={this.state.priceFeatureInfoUrl}
                                title="AccountPriceFeature"
                                style={{ height: "72vh", minWidth: "-webkit-fill-available" }}
                            >  </iframe> :
                                <div className="justify-content-center align-items-center d-flex h-100" style={{ minHeight: '35vh' }}>
                                    <img src={LoaderGif} alt="loading" style={{ height: '50px' }} />
                                </div>}
                        </Bootstrapodal.Body>
                    </Bootstrapodal>
                    <DownloadsModal displayModal={this.state.showDownloads} closeModal={this.hideDownloads} orgId={this.state.orgId} refresh={this.state.isRestoreDownloadRefreshed} />
                    {this.state.showAddtionLines == true &&
                        <AdditionLineControlModal displayModal={this.state.showAddtionLines} closeModal={this.closeModal} />}

                    <AlertModal displayModal={this.state.alertPop} closeModal={this.cancelAlertClick} message={this.state.alertMessage} />
                </nav>
            </React.Fragment>
        );
    }
}
