import Axios from "axios";
//import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState, useContext } from "react";
import "../../CSS/general.css";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import { loginAuthProvider } from "../../LoginAuthProvider";
//import Spinner from "reactstrap/lib/Spinner";
import Form from 'react-bootstrap/Form';
import AuthenticationContext from "../../Store/Authentication-Context";
import LoaderGif from "../../images/365 loading.gif"; 
import DotsTyping from "../../images/DotsTyping.gif";

const RepoModalPopup = ({ show, popupSwap, callingEvent, repository, encryptionEnabled, baseUrl, orgId, orgName, clearData, cancel, alertPop, repoArray, callbackRepoRefresh, repoModalClass, retention, immutability }) => {
    const [showModal, setShow] = useState(show);
    const { setAuthContext } = useContext(AuthenticationContext);
    const [disableBtn, setDisableBtn] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState("");
    const [errorEncryption, setErrorEncryption] = useState("");

    const [repo, setRepo] = useState({
        id: "",
        name: "",
        retentionType: "",
        retentionPolicy: "",
        retentionCount: "",
        encryption: true,
        baseUrl: baseUrl,
        orgId: orgId,
        encryptionKey: "",
        description: ""
    });
    let responseData = [];
    const cancelClick = (param) => {
        cancel();
    }
    const textChange = (params) => {
        setError("");
        setRepo({ ...repo, name: params.target.value });
    }
    const countChange = (params) => {
        setRepo({ ...repo, retentionCount: params.target.value });
    }
    const retentionTypeChange = (params) => {
        setRepo({ ...repo, retentionType: params.target.value });
    }
    const encryptionChange = (params) => {
        setRepo({ ...repo, encryption: params.target.value === 'true' });
    }
    const typeChange = (params) => {
        setRepo({ ...repo, description: params.target.value });
    }
    const encryptionKeyChange = (params) => {
        setErrorEncryption("");
        setRepo({ ...repo, encryptionKey: params.target.value });
    }
    const periodChange = (params) => {
        setRepo({ ...repo, retentionPolicy: params.target.value });
    }
    const divStyle = {
        display: show ? "block" : "none",
        zIndex: "10002",
        width:"27rem"
    };

    const handleShow = (param) => {
        setError("");
        setErrorEncryption("");
        setShow(param);
        popupSwap(param, true);

        setRepo(prev => ({
            id: "",
            name: "",
            retentionType: "",
            retentionPolicy: "",
            retentionCount: "",
            encryption: true,
            orgId: orgId,
            baseUrl: baseUrl,
            encryptionKey: ""
        }));
    }

    const submitBtnClick = async (param) => {
        setInProgress(true);
        if (repo.name === "" && repoArray.length === 0) {
            setError("Required");
            setInProgress(false);
            return;
        }

        if ((repo.encryptionKey === "" || repo.encryptionKey === undefined) && !encryptionEnabled) {
            setErrorEncryption("Required");
            setInProgress(false);
            return;
        }

        if (!encryptionEnabled) {
            var tokenString = await setAuthContext( new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                },
            };

            const payloadItem = {
                password: repo.encryptionKey,
                description: orgName
            }

            const payload = {
                orgId: orgId,
                postString: JSON.stringify(payloadItem),
                method: "POST",
                url: "/EncryptionKeys"
            }

            const data = await Axios.post(API_ROUTE + Routes.UPDATE_ENCRYPTIONKEY, payload, config)
                .then((response) => {
                    if (response.status == "200") {
                        createRepository(param);
                    }
                    return response;
                })
                .catch((reason) => {
                    setInProgress(false);
                    alertPop("Error: " + reason.response.data.message);
                });
        }
        else {
            createRepository(param);
        }
    }

    async function createRepository(param) {
        responseData = [];
        if (callingEvent === "Create Repositories") {
            if (repoArray.length > 0) {
                for (var i = 0; i < repoArray.length; i++) {
                    let repoDesc = getRepoDescription(repoArray[i]);
                    const payloadRepo = {
                        id: repo.id,
                        name: repoArray[i],
                        retentionType: repo.retentionType ? repo.retentionType : "SnapshotBased",
                        retentionPolicy: repo.retentionPolicy ? repo.retentionPolicy : "1 Year",
                        retentionCount: repo.retentionCount ? repo.retentionCount : "0",
                        encryption: repo.encryption,
                        baseUrl: baseUrl,
                        orgId: orgId,
                        description: repoDesc
                    };

                    var wait = await setRepoData(payloadRepo);
                }
                setDisableBtn(false);
                if (responseData.length === repoArray.length) {
                    callbackRepoRefresh();
                    setInProgress(false);
                    alertPop(responseData);
                    popupSwap(param, true);
                }
            }
        }
        else if (callingEvent === "Create") {
            const payload = {
                id: repo.id,
                name: repo.name,
                retentionType: repo.retentionType ? repo.retentionType : "SnapshotBased",
                retentionPolicy: repo.retentionPolicy ? repo.retentionPolicy : "1 Year",
                retentionCount: repo.retentionCount ? repo.retentionCount.toString() : "0",
                encryption: repo.encryption,
                baseUrl: baseUrl,
                orgId: orgId,
                description: repo.description
            };
            var wait = await setData(payload, param);
            callbackRepoRefresh();
        }
        else {
            const payload = {
                id: repo.id,
                name: repo.name,
                retentionType: repo.retentionType ? repo.retentionType : "SnapshotBased",
                retentionPolicy: repo.retentionPolicy ? repo.retentionPolicy : "1 Year",
                retentionCount: repo.retentionCount ? repo.retentionCount.toString() : "0",
                encryption: repo.encryption,
                baseUrl: baseUrl,
                orgId: orgId
            };
            var wait = await setData(payload, param);
        }
    }

    async function setData(payloadRepo, param) {
        setDisableBtn(true);
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };
        const data = await Axios.post(API_ROUTE + Routes.UPDATE_REPOSITORY, payloadRepo, config)
            .then((response) => {
                if (response.status == "200") {
                    if (callingEvent == "Create") {
                        alertPop("New Repository successfully created");
                    }
                    else {
                        setDisableBtn(false);
                        alertPop("Repository successfully updated");
                    }
                    setInProgress(false);
                    popupSwap(param, true);
                }

                setDisableBtn(false);
                return response;
            })
            .catch((reason) => {
                setDisableBtn(false);
                setInProgress(false);
                if (reason.response.data.message.includes("already exists"))
                    alertPop("Repository name- " + repo.name + " has been previously used, Please use a different name ");
                else
                    alertPop(reason.response.data.message);
                alertPop(reason.response.data.message);
            });

    }

    async function setRepoData(payloadRepo) {

        setDisableBtn(true);
        var tokenString = await setAuthContext(new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };

        const data = await Axios.post(API_ROUTE + Routes.UPDATE_REPOSITORY, payloadRepo, config)
            .then((response) => {
                if (response.status == "200") {
                    responseData.push(payloadRepo.name + " has been successfully created");
                }
                setDisableBtn(false);
                return response;
            })
            .catch((reason) => {
                if (reason.response.data.message.includes("already exists"))
                    responseData.push("Repository name- " + payloadRepo.name + " has been previously used, Please use a different name ");
                else
                    responseData.push(reason.response.data.message);
            });
    }

    function getRepoDescription(repoName) {
        if (repoName.toLowerCase().includes("mail") || repoName.toLowerCase().includes("archive"))
            return "MAIL"
        else if (repoName.toLowerCase().includes("onedrive"))
            return "ONEDRIVE"
        else
            return "SHAREPOINT"
    }

    let spinner = null;
    if (inProgress) {
        spinner =<><div className="justify-content-center align-items-end d-flex h-100" style={{ minHeight: '16vh' }}>
            <div style={{ display: 'grid', textAlign: 'center' }}>
                <img src={LoaderGif} alt="loading" style={{ height: '70px' }} />
            </div>

        </div>
            <div className="justify-content-center align-items-start d-flex h-100" style={{ minHeight: '16vh' }}>
                <div style={{ display: 'grid', textAlign: 'center' }}>
                    <span>Your repositories are being created  <img src={DotsTyping} alt="loading" style={{ height: '5px' }} /></span>
                </div>

            </div>
        </> 
    }

    useEffect(() => {
        handleShow(show);
        setShow(show);
    }, [show]);

    useEffect(() => {
        setRepo(prev => ({
            id: repository.id ? repository.id : "",
            name: repository.name ? repository.name : "",
            retentionType: repository.retentionType ? repository.retentionType : "",
            retentionPolicy: repository.retentionPolicy ? repository.retentionPolicy : "",
            retentionCount: repository.retentionCount ? repository.retentionCount : "",
            encryption: repository.encryption ? repository.encryption : false,
            orgId: orgId,
            baseUrl: baseUrl,
            description: repository.description
        }));
    }, [repository]);

    return (
        <div>
            {spinner != null ? <div id="ContentPlaceHolder1_PanelRep" style={divStyle} className={repoModalClass} align="left" >
                <div className="card popupborder">
                    {spinner}</div></div> : ((repo.retentionPolicy?.includes("Daily") &&
                (repo.retentionCount < 1 || repo.retentionCount > 30 || repo.retentionCount === "")) ||
                (repo.retentionPolicy?.includes("Monthly") &&
                    (repo.retentionCount < 1 || repo.retentionCount > 12 || repo.retentionCount === ""))) ? (
                <div id="ContentPlaceHolder1_PanelRep" style={divStyle} className={repoModalClass} align="left" >
                    <div className="card popupborder">
                        <div className="card-header">
                            <span id="ContentPlaceHolder1_RepNewEditTitle" className="SectionTitle">{callingEvent == "Create" || callingEvent == "Create Repositories" ? "ADD NEW REPOSITORY" : "UPDATE REPOSITORY"}</span>
                        </div>
                        <div className="card-body">
                            {
                                (callingEvent === "Create" || callingEvent === "Update") &&
                                <div className="form-group">
                                    <span id="ContentPlaceHolder1_lblTo">Name the Repository</span>
                                    <input type="text" id="ContentPlaceHolder1_txtRepName" className="form-control" aria-describedby="RepNameHelp" placeholder="Enter the name of your repository" required={true} style={{ width: "400px" }} onChange={textChange}
                                        value={repo.name}
                                        disabled={disableBtn}
                                    />
                                    <span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{error}</span>
                                </div>
                            }
                            <span id="ContentPlaceHolder1_lblRetentionType">Retention Type</span>
                            <select name="ctl00$ContentPlaceHolder1$retentionType" id="ContentPlaceHolder1_retentionType" className="form-select" value={repo.retentionType} onChange={retentionTypeChange} disabled={callingEvent === "Update" || callingEvent === "Create Repositories" || disableBtn}>
                                <option value="SnapshotBased">Snapshot Based</option>
                                <option value="ItemLevel">Item Level</option>

                            </select>
                            <span id="ContentPlaceHolder1_RetPeriod">Retention Period</span>
                                    <select id="ContentPlaceHolder1_retentionPolicy" className="form-select" value={repo.retentionPolicy !== "" ? repo.retentionPolicy : "1 Year"} onChange={periodChange} disabled={callingEvent === "Update" || callingEvent === "Create Repositories" || disableBtn}>
                                {Array.from({ length: parseInt(retention) }, (_, index) => {
                                    const iterationYear = index + 1;
                                    return iterationYear !== 4 && iterationYear !== 6 && (
                                        <option key={index} value={`${iterationYear} Year${index === 0 ? "" : "s"}`}>
                                            {iterationYear} {index === 0 ? "Year" : "Years"}
                                        </option>
                                    );
                                })}
                                {/*<option value="1 Year">1 Year</option>*/}
                                {/*<option value="2 Years">2 Years</option>*/}
                                {/*<option value="3 Years">3 Years</option>*/}
                                {/*<option value="5 Years">5 Years</option>*/}
                                {/*<option value="7 Years">7 Years</option>*/}
                                {/*<option value="Daily">Specify no of days</option>*/}
                                <option value="Monthly">Specify no of months</option>
                            </select>
                            {
                                (callingEvent === "Create") &&
                                <div>
                                    <span id="ContentPlaceHolder1_RepoType">Type</span>
                                    <select id="ContentPlaceHolder1_retentionPolicy" className="form-select" value={repo.description != "" ? repo.description : "MAIL"} onChange={typeChange} disabled={disableBtn}>
                                        <option value="MAIL">Mail</option>
                                        <option value="ONEDRIVE">Onedrive</option>
                                        <option value="SHAREPOINT">Others</option>
                                    </select>
                                </div>
                            }
                            {
                                (callingEvent === "Update" && repo.description !== "") &&
                                <div>
                                    <span id="ContentPlaceHolder1_RepoType">Type</span>
                                    <select id="ContentPlaceHolder1_retentionPolicy" className="form-select" value={repo.description} onChange={typeChange} disabled>
                                        <option value="MAIL">Mail</option>
                                        <option value="ONEDRIVE">Onedrive</option>
                                        <option value="SHAREPOINT">Others</option>
                                    </select>
                                </div>
                            }
                            <span id="ContentPlaceHolder1_Count" style={{ display: !repo.retentionPolicy?.includes("Year") && repo.retentionPolicy !== "" ? "block" : "none" }}>How many:</span>
                            {/*<input type="number" id="ContentPlaceHolder1_txtCount" style={{ width: "400px", display: !repo.retentionPolicy?.includes("Year") ? "block" : "none" }}*/}
                            {/*    className="form-control"  placeholder={repo.retentionPolicy?.includes("Daily") ? "Days" : repo.retentionPolicy?.includes("Monthly") ? "Months" : ""} required="" onChange={countChange}*/}
                            {/*    defaultValue={repo.retentionCount} disabled={disableBtn}  */}
                            {/*/>*/}

                            <Form.Control type="number" id="ContentPlaceHolder1_txtCount" style={{ width: "400px", display: !repo.retentionPolicy?.includes("Year") && repo.retentionPolicy !== "" ? "block" : "none" }}
                                className="form-control" placeholder={repo.retentionPolicy?.includes("Daily") ? "Days" : repo.retentionPolicy?.includes("Monthly") ? "Months" : ""} required="" onChange={countChange}
                                disabled={disableBtn} defaultValue={repo.retentionCount}
                                isInvalid={repo.retentionCount !== "" && repo.retentionPolicy?.includes("Daily")
                                    ? repo.retentionCount < 1 || repo.retentionCount > 30
                                    : repo.retentionCount !== "" && repo.retentionPolicy?.includes("Monthly")
                                        ? repo.retentionCount < 1 || repo.retentionCount > 12
                                        : false
                                } />
                            <Form.Control.Feedback type="invalid" >
                                {repo.retentionPolicy?.includes("Daily") &&
                                    ((repo.retentionCount < 1 && "Day must be greater than 1") ||
                                        (repo.retentionCount > 30 && "Day must be less than 30"))}
                                {repo.retentionPolicy?.includes("Monthly") &&
                                    ((repo.retentionCount < 1 && "Month must be greater than 1") ||
                                        (repo.retentionCount > 12 && "Month must be less than 12"))}
                            </Form.Control.Feedback>
                            {
                                !encryptionEnabled &&
                                <div>
                                    <span id="ContentPlaceHolder1_Encryption" >Please enter a new Encryption key</span>
                                    <input type="text" id="encryptionKey" className="form-control" placeholder="Encryption key..." style={{ width: "400px" }} onChange={encryptionKeyChange}
                                        value={repo.encryptionKey ?? ''}
                                        disabled={disableBtn} />
                                    <span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{errorEncryption}</span>
                                </div>
                            }
                            {
                                encryptionEnabled &&
                                <div>
                                    <span id="ContentPlaceHolder1_Encryption" style={{ display: "block" }} >Encryption</span>
                                    <select id="ContentPlaceHolder1_EcryptionDrpdwn" className="form-select" value={repo.encryption} style={{ display: "block" }} onChange={encryptionChange} disabled={callingEvent === "Update" || disableBtn}>
                                        <option value={false}>Disabled</option>
                                        <option value={true}>Enabled</option>
                                    </select>
                                </div>
                            }
                            <div id="ContentPlaceHolder1_ssss">
                                <div className="form-group">
                                </div>
                            </div>
                            <input type="hidden" name="ctl00$ContentPlaceHolder1$RepID" id="ContentPlaceHolder1_RepID" />
                        </div>
                        <div className="card-footer d-flex">
                            <input type="submit" name="ctl00$ContentPlaceHolder1$RepCancel" className="form-control" value="Cancel" id="ContentPlaceHolder1_RepCancel" onClick={() => handleShow(false)} hidden={disableBtn} />
                            &nbsp;
                            <input type="submit" className="form-control" name="ctl00$ContentPlaceHolder1$RepAdd" value={callingEvent} id="ContentPlaceHolder1_RepAdd" disabled style={{ cursor: "not-allowed" }} />
                        </div>
                    </div>
                </div>
            ) : (
                <div id="ContentPlaceHolder1_PanelRep" style={divStyle} className={repoModalClass} align="left" >
                    <div className="card popupborder">
                        <div className="card-header">
                            <span id="ContentPlaceHolder1_RepNewEditTitle" className="SectionTitle">{callingEvent == "Create" || callingEvent == "Create Repositories" ? "ADD NEW REPOSITORY" : "UPDATE REPOSITORY"}</span>
                        </div>
                        <div className="card-body">
                            {
                                (callingEvent === "Create" || callingEvent === "Update") &&
                                <div className="form-group">
                                    <span id="ContentPlaceHolder1_lblTo">Name the Repository</span>
                                    <input type="text" id="ContentPlaceHolder1_txtRepName" className="form-control" aria-describedby="RepNameHelp" placeholder="Enter the name of your repository" required={true} style={{ width: "400px" }} onChange={textChange}
                                        value={repo.name}
                                        disabled={disableBtn}
                                    />
                                    <span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{error}</span>
                                </div>
                            }
                            <span id="ContentPlaceHolder1_lblRetentionType">Retention Type</span>
                            <select name="ctl00$ContentPlaceHolder1$retentionType" id="ContentPlaceHolder1_retentionType" className="form-select" value={repo.retentionType} onChange={retentionTypeChange} disabled={callingEvent === "Update" || callingEvent === "Create Repositories" || disableBtn}>
                                <option value="SnapshotBased">Snapshot Based</option>
                                <option value="ItemLevel">Item Level</option>

                            </select>
                            <span id="ContentPlaceHolder1_RetPeriod">Retention Period</span>
                                    <select id="ContentPlaceHolder1_retentionPolicy" className="form-select" value={repo.retentionPolicy !== "" ? repo.retentionPolicy : "1 Year"} onChange={periodChange} disabled={callingEvent === "Update" || callingEvent === "Create Repositories" || disableBtn}>
                                {Array.from({ length: parseInt(retention) }, (_, index) => {
                                    const iterationYear = index + 1;
                                    return iterationYear !== 4 && iterationYear !== 6 && (
                                        <option key={index} value={`${iterationYear} Year${index === 0 ? "" : "s"}`}>
                                            {iterationYear} {index === 0 ? "Year" : "Years"}
                                        </option>
                                    );
                                })}
                                {/*<option value="1 Year">1 Year</option>*/}
                                {/*<option value="2 Years">2 Years</option>*/}
                                {/*<option value="3 Years">3 Years</option>*/}
                                {/*<option value="5 Years">5 Years</option>*/}
                                {/*<option value="7 Years">7 Years</option>*/}
                                {/*<option value="Daily">Specify no of days</option>*/}
                                <option value="Monthly">Specify no of months</option>
                            </select>
                            {
                                (callingEvent === "Create") &&
                                <div>
                                    <span id="ContentPlaceHolder1_RepoType">Type</span>
                                    <select id="ContentPlaceHolder1_retentionPolicy" className="form-select" value={repo.description != "" ? repo.description : "MAIL"} onChange={typeChange} disabled={disableBtn}>
                                        <option value="MAIL">Mail</option>
                                        <option value="ONEDRIVE">Onedrive</option>
                                        <option value="SHAREPOINT">Others</option>
                                    </select>
                                </div>
                            }
                            {
                                (callingEvent === "Update" && repo.description !== "") &&
                                <div>
                                    <span id="ContentPlaceHolder1_RepoType">Type</span>
                                    <select id="ContentPlaceHolder1_retentionPolicy" className="form-select" value={repo.description} onChange={typeChange} disabled>
                                        <option value="MAIL">Mail</option>
                                        <option value="ONEDRIVE">Onedrive</option>
                                        <option value="SHAREPOINT">Others</option>
                                    </select>
                                </div>
                            }
                            <span id="ContentPlaceHolder1_Count" style={{ display: !repo.retentionPolicy?.includes("Year") && repo.retentionPolicy !== "" ? "block" : "none" }}>How many:</span>
                            {/*<input type="number" id="ContentPlaceHolder1_txtCount" style={{ width: "400px", display: !repo.retentionPolicy?.includes("Year") ? "block" : "none" }}*/}
                            {/*    className="form-control"  placeholder={repo.retentionPolicy?.includes("Daily") ? "Days" : repo.retentionPolicy?.includes("Monthly") ? "Months" : ""} required="" onChange={countChange}*/}
                            {/*    defaultValue={repo.retentionCount} disabled={disableBtn}  */}
                            {/*/>*/}

                            <Form.Control type="number" id="ContentPlaceHolder1_txtCount" style={{ width: "400px", display: !repo.retentionPolicy?.includes("Year") && repo.retentionPolicy !== "" ? "block" : "none" }}
                                className="form-control" placeholder={repo.retentionPolicy?.includes("Daily") ? "Days" : repo.retentionPolicy?.includes("Monthly") ? "Months" : ""} required="" onChange={countChange}
                                disabled={disableBtn} defaultValue={repo.retentionCount}
                                isInvalid={repo.retentionCount !== "" && repo.retentionPolicy?.includes("Daily")
                                    ? repo.retentionCount < 1 || repo.retentionCount > 30
                                    : repo.retentionCount !== "" && repo.retentionPolicy?.includes("Monthly")
                                        ? repo.retentionCount < 1 || repo.retentionCount > 12
                                        : false
                                } />
                            <Form.Control.Feedback type="invalid" >
                                {repo.retentionPolicy?.includes("Daily") &&
                                    ((repo.retentionCount < 1 && "Day must be greater than 1") ||
                                        (repo.retentionCount > 30 && "Day must be less than 30"))}
                                {repo.retentionPolicy?.includes("Monthly") &&
                                    ((repo.retentionCount < 1 && "Month must be greater than 1") ||
                                        (repo.retentionCount > 12 && "Month must be less than 12"))}
                            </Form.Control.Feedback>
                            {
                                !encryptionEnabled &&
                                <div>
                                    <span id="ContentPlaceHolder1_Encryption" >Please enter a new Encryption key</span>
                                    <input type="text" id="encryptionKey" className="form-control" placeholder="Encryption key..." style={{ width: "400px" }} onChange={encryptionKeyChange}
                                        value={repo.encryptionKey ?? ''}
                                        disabled={disableBtn} />
                                    <span id="WizardRequiredFieldValidatorJobName" style={{ color: "red" }} >{errorEncryption}</span>
                                </div>
                            }
                            {
                                encryptionEnabled &&
                                <div>
                                    <span id="ContentPlaceHolder1_Encryption" style={{ display: "block" }} >Encryption</span>
                                    <select id="ContentPlaceHolder1_EcryptionDrpdwn" className="form-select" value={repo.encryption} style={{ display: "block" }} onChange={encryptionChange} disabled={callingEvent === "Update" || disableBtn}>
                                        <option value={false}>Disabled</option>
                                        <option value={true}>Enabled</option>
                                    </select>
                                </div>
                            }
                            <div id="ContentPlaceHolder1_ssss">
                                <div className="form-group">
                                </div>
                            </div>
                            <input type="hidden" name="ctl00$ContentPlaceHolder1$RepID" id="ContentPlaceHolder1_RepID" />
                        </div>
                        <div className="card-footer d-flex">
                            <input type="submit" name="ctl00$ContentPlaceHolder1$RepCancel" className="form-control" value="Cancel" id="ContentPlaceHolder1_RepCancel" onClick={() => handleShow(false)} hidden={disableBtn} />
                            &nbsp;
                            <input type="submit" className="form-control" name="ctl00$ContentPlaceHolder1$RepAdd" value={callingEvent} id="ContentPlaceHolder1_RepAdd" onClick={() => submitBtnClick(false)} hidden={disableBtn} />
                        </div>
                    </div>
                </div>
            )}
            
            <div data-act-control-type="modalPopupBackground" id="RepAdd_backgroundElement" className="Background" style={{ display: show ? "block" : "none", backgroundColor: "black", opacity: "0.8", position: "fixed", left: "0px", top: "0px", zIndex: "10000", width: "100%", height: "100%" }} />
        </div>
    );
}
export default RepoModalPopup;