import React, { useState, useEffect, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../CSS/general.css";
import { AgGridReact } from 'ag-grid-react';
import RepoModalPopup from "./RepoModalPopup";
import { loginAuthProvider } from "../../LoginAuthProvider";
import Axios from "axios";
import { API_ROUTE, Routes } from "../../Helpers/Constants";
import HelpModal from "./HelpMessagePopup";
import NotificationModal from "./NotificationPopup";
import AlertModal from "./AlertMessagePopup";
import { AppContext } from "./AppContext";
import Button from 'react-bootstrap/Button';
import immutableImage from "../../images/binary.png";
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthenticationContext from "../../Store/Authentication-Context";
import LoaderGif from "../../images/365 loading.gif";


const Repositories = ({ repositories, baseUrl, orgId, orgName, priceModel, isAzureGuardianOnly, activeTab, azureRepo }) => {
    const gridRef = useRef();
    const azureGridRef = useRef();
    const { setAuthContext ,setRepoData, checkDataForId, RepoData } = useContext(AuthenticationContext);
    const [gridApi, setGridApi] = useState([]);
    const [gridAzureApi, setGridAzureApi] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [appContext,setrepoContext] = useContext(AppContext);
 
    const [repos, setRepos] = useState(repositories);

    const [repoCallingEvent, setRepoCallingEvent] = useState("");
    const [popUpRepo, setPopUpRepo] = useState([]);
    const [repoSelectedSelfUrl, setRepoSelectedSelfUrl] = useState("");
    const [rowData, setRowData] = useState(repos.Repositories);
    const [azureRowData, setAzureRowData] = useState(azureRepo);
    //if (repositories && repositories.repositories.length == 0)
    //    toast("Create new repository to start backup jobs!", { autoClose: false });
    const [helpPopup, setHelpPopup] = useState(true);
    const [notificationPopup, setNotificationPopup] = useState(true);
    const [popupMessage, setPopupMessage] = useState("");
    const [action, setAction] = useState("");
    const [AlertPop, setAlertPop] = useState(true);
    const [backupRepo, setBackupRepo] = useState([]);
    const [alertMessage, setAlertMessage] = useState({
        header: "",
        message: "",
    });    
    const rowHeight = 29;
    const headerHeight = 30;

    async function loadRepo() {
   
        gridRef.current.api.showLoadingOverlay();
        if (!(gridRef.current.api))
            return;
        var tokenString = await setAuthContext( new Date());
        const config = {
            headers: {
                Authorisation: `Bearer ` + tokenString,
            },
        };

        let repoData = null;
        await Axios.get(API_ROUTE + Routes.GET_REPO + "?orgId=" + orgId + "&orgName=" + orgName + "&baseUrl=" + baseUrl, config)
            .then((response) => {
                let selectItems = JSON.parse(response.data);
                gridRef.current.api.hideOverlay();
                repoData = selectItems;
                //appContext.repositories.Repositories = selectItems.Repositories;
             
                setrepoContext((prev) => {
                    return {
                        ...prev,
                        repositories: repoData
                    }
                });
            })
            .catch((reason) => {
                //console.log("catch Repo ; " + reason);
            });
        if (repoData) {
            setRepos(repoData);
            gridRef.current.api.setRowData(repoData.Repositories);

            gridRef.current.api.hideOverlay();
        }
    }
    const getRowStyle = params => {

        /* if (params.node.rowIndex % 2 !== 0) {
             return { background: "white", borderColor: "white", fontSize: "Small", };
         } else {
             return { background: "#F0F0F0", borderColor: "#F0F0F0", fontSize: "Small" };
         }*/
        return { fontSize: "Small" };
    };
    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    function onAzureFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }
    function showModalPopupEdit(action, params) {

        setRepoCallingEvent(action);
        //setSelectedRepo(params);
        SelecteRepoCreate(params);
        setShowModal(true);
    }

    function showModalPopupCreate(action, params) {

        setRepoCallingEvent(action);
        SelecteRepoCreate(params);
        setShowModal(true);
    }

    function closeModal(params) {
        setHelpPopup(params);
    }

    function closeAlertModal(alertMessage) {
        setAlertMessage({
            header: "",
            message: alertMessage,
        });
        setAlertPop(false);
    }

    function closeNotificationModal(params) {
        setNotificationPopup(params);
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        //loadRepo(params.api);
        params.api.sizeColumnsToFit();
    }
    const onAzureGridReady = (params) => {
        setGridAzureApi(params.api);
        //loadRepo(params.api);
        params.api.sizeColumnsToFit();
    }
    const popupSwap = (show, reload = false) => {
        if (reload) {
            loadRepo();
        }
        setShowModal(show);
    }

    const cancel = () => {
        setShowModal(false);
    }


    const clearData = () => {
        setRowData([]);
    }

    function cancelAlertClick() {
        setAlertPop(true);
    }


    const onFilterTextChange = (e) => {
        gridApi.setQuickFilter(e.target.value);
    }
    useEffect(() => {
       
    }, [appContext.repositories.Repositories]);

    async function deleteRepo(params) {
        async function setData() {
            if (!params)
                return;
            var tokenString = await setAuthContext( new Date());
            const config = {
                headers: {
                    Authorisation: `Bearer ` + tokenString,
                },
            };
            let payload = {
                postString: "",
                method: "DELETE",
                url: repoSelectedSelfUrl,
                orgId: appContext.orgId,
                backupRepo: backupRepo,
            };

            const data = await Axios.post(API_ROUTE + Routes.SET_JOB, payload, config)
                .then((response) => {
                    loadRepo()
                    return (response);
                })
                .catch((reason) => {
                    closeAlertModal(reason.response.data.message);
                });
        }
        var deleteWait = await setData();

    }
    
    const columnDefs = [
        {
            field: 'name', headerName: '', cellStyle: { 'text-align': 'left' }, minWidth:77,flex:1,
            cellRendererFramework: (params) => <div>
                {params.data.name.toLocaleLowerCase().includes('-copy') ? (<><a id="dummyLinkButton" title="Edit Repository" onClick={() => { return }} style={{ pointerEvents: "none" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="gray" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                    </svg>
                </a> &nbsp;</>) : <><a id={"ButtonEditRep" + params.data.name} title="Edit Repository" onClick={() => showModalPopupEdit("Update", params.data)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                        </svg>
                </a> &nbsp;</>}
                <a id={"ButtonDeleteRepJob" + params.data.name} title="Delete Repository" onClick={() => {
                    closeNotificationModal("Delete", params.data.name, params.data.fullName);
                }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                </a>

            </div>
        },

        {
            field: 'name', headerName: 'Name', cellStyle: { 'text-align': 'left' }, filter: true, minWidth: 130, flex: 1
        },
        {
            field: 'retentionType', headerName: 'Type', wrapText: true, cellStyle: { 'text-align': 'left' }, filter: true, minWidth: 130, flex: 1
        },
        { field: 'thePeriod', headerName: 'Period', cellStyle: { 'text-align': 'left' }, minWidth: 80, flex: 1 },
        { field: 'size', headerName: 'GB', cellStyle: { 'text-align': 'left' },minWidth: 60, flex: 1 },
        {
            field: 'id', headerName: 'Loc', cellStyle: { 'text-align': 'left' }, minWidth: 60, flex: 1,
            cellRendererFramework: (params) => <>{params.data.objectstoragerepositories.amazonBucketS3Compatible.id ? params.data.objectstoragerepositories.amazonBucketS3Compatible.id === 0 ? "-" : params.data.objectstoragerepositories.amazonBucketS3Compatible.id : "-"}</>                
},
        {
            field: 'size', headerName: '', cellStyle: { 'text-align': 'left' }, minWidth: 50, flex: 1
            ,
            cellRendererFramework: (params) => <div>
                {!params.data.name.toLocaleLowerCase().includes('-copy') ?
                    //<i className={(params.data.ObjectStorageEncryptionEnabled ? "fas fa-key" : "fas fa-object-group")} title={params.data.ObjectStorageEncryptionEnabled ? "Repository encrypted" : "Repository not encrypted"}></i> :
                    params.data.ObjectStorageEncryptionEnabled ?
                        <svg title="Repository encrypted" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title id="fa_key">Repository encrypted</title><path fill="currentColor" d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"></path></svg>
                        : <svg title="Repository encrypted" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title id="object_group">Repository not encrypted</title><path fill="currentColor" d="M480 128V96h20c6.627 0 12-5.373 12-12V44c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v20H64V44c0-6.627-5.373-12-12-12H12C5.373 32 0 37.373 0 44v40c0 6.627 5.373 12 12 12h20v320H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-20h384v20c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-20V128zM96 276V140c0-6.627 5.373-12 12-12h168c6.627 0 12 5.373 12 12v136c0 6.627-5.373 12-12 12H108c-6.627 0-12-5.373-12-12zm320 96c0 6.627-5.373 12-12 12H236c-6.627 0-12-5.373-12-12v-52h72c13.255 0 24-10.745 24-24v-72h84c6.627 0 12 5.373 12 12v136z"></path></svg> :
                    <img
                        src={immutableImage}
                        alt="Immutable"
                        title="Immutable"
                        style={{ width: "25px", height: "25px" }}
                    />}
            </div>
        },
    ];
    //<i style="color:green;" class="fas fa-object-group"></i>
    const azureColumnDefs = [
        {
            field: 'bucketName', headerName: 'Name', cellStyle: { 'text-align': 'left' }, minWidth: 290, flex: 1
        },
        {
            field: 'retentionType', headerName: 'Type', wrapText: true, cellStyle: { 'text-align': 'left' }, minWidth: 110, flex: 1, valueGetter: () => { return "Guardian"; }
        
        },
        {
            headerName: 'Period', cellStyle: { 'text-align': 'left' }, minWidth: 80, flex: 1,
            valueGetter: () => { return "1 Year";}},
        {
            field: 'size', headerName: 'GB', cellStyle: { 'text-align': 'left' }, minWidth: 60, flex: 1, resizable:true,
            valueFormatter: (azureparams) => {
                const roundedValue = azureparams.data.size ? azureparams.data.size.toFixed(3) : '0';
                return roundedValue;
            }
                },
        {
            field: 'size', headerName: '', cellStyle: { 'text-align': 'left' }, minWidth: 70, flex: 1
            ,
            cellRendererFramework: (azureparams) => <div>
                <svg title="Repository encrypted" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title id="fa_key">Repository encrypted</title><path fill="currentColor" d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"></path></svg>
            </div>
        },
    ];

    function closeNotificationModal(action, repoName, fullName) {
        setAction(action);
        setBackupRepo({
            id: "",
            name: fullName,
        });
        if (action === "Delete")
            setPopupMessage("Are you sure you want to delete the Repository - " + repoName);
        setNotificationPopup(false);
    }

    const onRowClicked = (params) => {
        setRepoSelectedSelfUrl(params.data._links.self.href);
    }

    function cancelClick() {
        setNotificationPopup(true);
    }

    function submitClick(action) {


        if (action === "Delete")
            deleteRepo(gridApi);

        setNotificationPopup(true);
    }

    const SelecteRepoCreate = (selectedRepo) => {
        if (selectedRepo && selectedRepo.id && selectedRepo.id !== "") {
            let retPolicy = selectedRepo.retentionPeriodType;
            let retCount;
            switch (selectedRepo.retentionPeriodType) {
                case "Daily": {
                    retCount = selectedRepo.dailyRetentionPeriod;
                    break;
                }
                case "Monthly": {
                    retCount = selectedRepo.monthlyRetentionPeriod;
                    break;
                }
                default: {

                    retPolicy = selectedRepo.yearlyRetentionPeriod;
                }
            }
            setPopUpRepo(prev => ({
                id: selectedRepo.id,
                name: selectedRepo.name,
                retentionType: selectedRepo.retentionType,
                retentionPolicy: retPolicy,
                retentionCount: retCount,
                encryption: selectedRepo.ObjectStorageEncryptionEnabled,
                baseUrl: baseUrl,
                description: selectedRepo.description ? selectedRepo.description : "",
            }));
        }
        else {
            setPopUpRepo({
                id: "",
                name: "",
                retentionType: "SnapshotBased",
                retentionPolicy: "1 Year",
                retentionCount: 0,
                encryption: true,
                baseUrl: baseUrl,
                description: "MAIL"
            })
        }
    };

    const reposRefresh = () => {
        loadRepo();
        // Need method to update the appContext with newly updated/added repos
    }

    useEffect(() => {
        setRepos(repositories);
        setRowData(repositories.Repositories);
    }, [repositories]);
    useEffect(() => {
        if (azureRepo && azureRepo.length > 0) {
            setAzureRowData(azureRepo);
        }
        
    }, [azureRepo]);

    return (
        <div className="card mt-1" id="repositories">
            <div className="card-header p-1 d-flex align-items-center">
                <span className="SectionTitle ms-2" style={{ fontWeight: '500', width: '35%' }}>Repositories &nbsp;
                    
                </span>
                <div className="d-flex justify-content-end SectionTitle" style={{  width: '65%' }}>
                    {!isAzureGuardianOnly && activeTab === "365" && (rowData ?
                        <a id="ContentPlaceHolder1_btnAddRep" style={{ color: "#007bff" }} onClick={() => showModalPopupCreate("Create", null)}>
                            
                                <svg style={{  marginBottom: '.1rem' }} className="me-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z">
                                </path></svg>
                                New Repository </a> :
                        <a id="ContentPlaceHolder1_btnAddRep" style={{ color: "#007bff", cursor: 'not-allowed' }}>
                            
                                <svg style={{  marginBottom: '.1rem' }} className="me-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z">
                                </path></svg>
                                New Repository </a>)
                    }
                    
                    
                    {!isAzureGuardianOnly && activeTab === "365" && <>
                        <a id="ContentPlaceHolder1_btnReload" onClick={() => loadRepo()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 512 512" className="me-2 ms-2">
                                <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z">
                                </path>
                            </svg> </a>
                        <a id="ContentPlaceHolder1_btnClear" style={{ color: "#007bff", cursor: 'pointer', height: "25", width: "25" }} className="me-1" onClick={(e) => {
                            if (gridApi) {
                                gridApi.setFilterModel(null);
                            }
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="23" stroke="#007bff" fill="#007bff" viewBox="0 0 32 32" width="23" style={{ cursor: 'pointer' }}>
                                <path strokeWidth="1.5" d="m30 11.414-1.414-1.414-4.586 4.586-4.586-4.586-1.414 1.414 4.586 4.586-4.586 4.585 1.415 1.415 4.585-4.586 4.587 4.586 1.413-1.413-4.586-4.587z"></path>
                                <path strokeWidth="1.5" d="m4 4a2 2 0 0 0 -2 2v3.1709a2 2 0 0 0 .5859 1.4145l7.4141 7.4146v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h-2v2h-4v-8.8291l-.5859-.5855-7.4141-7.4145v-3.1709h20v2h2v-2a2 2 0 0 0 -2-2z"></path>
                            </svg>
                        </a>
                        <a id="ContentPlaceHolder1_btnHelp" onClick={() => closeModal(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 384 512" className="">
                            <g >
                                <path fill="currentColor" d="M182.4,373.5c-38.3,0-69.3,31-69.3,69.3s31,69.3,69.3,69.3c38.3,0,69.3-31,69.3-69.3 C251.7,404.5,220.7,373.5,182.4,373.5z"></path><path fill="currentColor" d="M367.9,153.6c0,116-125.3,117.8-125.3,160.6v5.8c0,13.3-10.7,24-24,24h-72.5c-13.3,0-24-10.7-24-24v-9.8 c0-61.8,46.9-86.5,82.3-106.4c30.4-17,49-28.6,49-51.2c0-29.8-38-49.6-68.8-49.6c-39.1,0-57.8,18.1-82.8,49.4 c-8.1,10.2-22.9,12-33.3,4.1l-43.1-32.7c-10.3-7.8-12.6-22.3-5.2-32.9C60.9,32.7,112.6,0,192.4,0C277.3,0,367.9,66.3,367.9,153.6z">
                                </path>
                            </g>
                        </svg> </a></> }
                    
                </div>
            </div>
            <div className="card-body p-0">
                <div id="ContentPlaceHolder1_pnlRespository" align="center" style={{ width: "100%", overflow: "100%", height: '231px', display: !isAzureGuardianOnly && activeTab === "365" ? "block":"none"}}>
                        <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 231, borderCollapse: "collapse" }} >
                            <AgGridReact style={{ width: "100%" }}
                                ref={gridRef}
                                rowData={rowData}
                                getRowStyle={getRowStyle}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                onRowClicked={onRowClicked}
                                onFirstDataRendered={onFirstDataRendered}
                                rowSelection="single"
                                enableCellTextSelection="true"
                                overlayLoadingTemplate={
                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`
                                }
                                rowBuffer={500}
                                overlayNoRowsTemplate={
                                    '<span className="ag-overlay-loading-center">No records to show</span>'
                                }
                                rowHeight={rowHeight}
                                headerHeight={headerHeight}
                                suppressScrollOnNewData={true}
                                columnDefs={columnDefs} id="ag_repo">
                            </AgGridReact>
                        </div>

                </div>
                <div id="ContentPlaceHolder1_pnlAzureRespository" align="center" style={{ width: "100%", overflow: "100%", height: '231px', display: !isAzureGuardianOnly && activeTab === "365" ? "none" : "block" }}>
                        <div className="ag-theme-alpine" style={{ borderColor: "#F0F0F0", borderStyle: "Solid", width: "100%", height: 231, borderCollapse: "collapse" }} >
                            <AgGridReact style={{ width: "100%" }}
                                ref={azureGridRef}
                                rowData={azureRowData}
                                getRowStyle={getRowStyle}
                                onGridReady={onAzureGridReady}
                                defaultColDef={defaultColDef}
                                onFirstDataRendered={onAzureFirstDataRendered}
                                rowSelection="single"
                                enableCellTextSelection="true"
                                overlayLoadingTemplate={
                                    `<div style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);"><div><div style="text-align: center;"><img src="${LoaderGif}" alt="loading" style="height: 50px;"></div><span>Please wait while your rows are loading...</span></div></div>`
                                }
                                rowBuffer={500}
                                overlayNoRowsTemplate={
                                    '<span className="ag-overlay-loading-center">No records to show</span>'
                                }
                                rowHeight={rowHeight}
                                headerHeight={headerHeight}
                                suppressScrollOnNewData={true}
                                columnDefs={azureColumnDefs} id="azureag_repo" >
                            </AgGridReact>
                        </div>

                    </div>
            </div>
            <RepoModalPopup show={showModal} popupSwap={popupSwap} callingEvent={repoCallingEvent} repository={popUpRepo} encryptionEnabled={repos.EncryptionEnabled} baseUrl={baseUrl} clearData={clearData} cancel={cancel} orgId={orgId} alertPop={closeAlertModal} repoArray={[]} callbackRepoRefresh={reposRefresh} orgName={orgName} repoModalClass={'create-repo-modal'} retention={(Object.keys(priceModel).length !== 0 && priceModel.features.length !== 0) ? priceModel.features.find(feature =>feature.featureType === "RETENTION").featureValue : 1} immutability={(Object.keys(priceModel).length !== 0 && priceModel.features.length !== 0) ? priceModel.features.find(feature => feature.featureType === "LOCALIMMUT").featureValue : "30"} />
            <HelpModal displayModal={!helpPopup} closeModal={closeModal} about="RepositoryManagement.html" />
            <NotificationModal displayModal={!notificationPopup} closeModal={cancelClick} message={popupMessage} submitClick={submitClick} action={action} />
            <AlertModal displayModal={!AlertPop} closeModal={cancelAlertClick} message={alertMessage} />
        </div>

    );

}
export default Repositories;